import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { Employee } from '../../utils/interfaces/email.model';
import { Role } from '../../utils/interfaces/role.model';
import { generateQueryString } from '../../utils/string';

interface ShareIssue {
    issueId: string;
    employees: Array<number>;
}
interface AddEmployee {
    email: string;
    roleId: number;
}
interface AddAndShare {
    issueId: string;
    employees: AddEmployee[];
}
type GetEmailResponse = { data: { data: { data: Employee[] } } };
type GetRoleResponse = { data: { data: Role[] } };
type PostShareIssue = ShareIssue;
type PostAddUserAndShareIssue = AddAndShare;

export const userApiSlice = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        prepareHeaders(headers) {
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: (builder) => ({
        getEmployeeEmails: builder.query<GetEmailResponse, string | void>({
            query: () => '/employees',
        }),
        getUserDetails: builder.query<any, void>({
            query: () => '/employees/user-info',
        }),
        getEmployeeRoles: builder.query<GetRoleResponse, string | void>({
            query: () => '/roles',
        }),
        logoutUserSession: builder.query<GetRoleResponse, string | void>({
            query: () => '/employees/logout',
        }),
        postShareIssue: builder.query<PostShareIssue, Partial<PostShareIssue>>({
            query: (body) => ({
                url: '/employees/assign',
                method: 'POST',
                body,
            }),
        }),
        postAddUserAndShareIssue: builder.query<
            PostAddUserAndShareIssue,
            Partial<PostAddUserAndShareIssue>
        >({
            query: (body) => ({
                url: '/employees/add-assign',
                method: 'POST',
                body,
            }),
        }),
        createUser: builder.query<
            {
                email: string;
                role: string;
            },
            any
        >({
            query: (data) => ({
                url: '/employees',
                method: 'post',
                credentials: 'include',
                body: data,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
        }),
        listUser: builder.query({
            query: (args) => {
                return `/employees?${generateQueryString(args)}`;
            },
            transformResponse: (response: any) => response.data,
        }),
        updateUser: builder.mutation({
            query: (args) => {
                return {
                    url: `/employees/${args.id}`,
                    method: 'PATCH',
                    credentials: 'include',
                    body: args.data,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                };
            },
        }),
        deleteUser: builder.mutation({
            query: (args) => {
                return {
                    url: `/employees/${args.id}`,
                    method: 'DELETE',
                    credentials: 'include',
                    body: args.data,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                };
            },
        }),
    }),
});

export const {
    useGetEmployeeEmailsQuery,
    useGetEmployeeRolesQuery,
    useLazyGetEmployeeRolesQuery,
    useLazyPostShareIssueQuery,
    useLazyPostAddUserAndShareIssueQuery,
    useLazyCreateUserQuery,
    useLazyListUserQuery,
    useLazyGetUserDetailsQuery,
    useLazyLogoutUserSessionQuery,
    useLazyGetEmployeeEmailsQuery,
    useUpdateUserMutation,
    useDeleteUserMutation,
} = userApiSlice;
