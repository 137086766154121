import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface IssueState {
    statusCode: number;
    message: string;
    data: {
        id: string;
        issueCreatedAt: string;
        title: string;
        description: string;
        user: {
            email: string;
            firstName: string;
            lastName: string;
        };
        topLevelDomain: string;
        file: {
            videoData: {
                data: Blob;
            };
            networkData: Object[] | { fileName: string };
            systemInfoData:
                | {
                      info: Object[];
                      speed: number;
                      ip: string;
                  }
                | { fileName: string };
            consoleLogData: Object[] | { fileName: string };
            practiceInfo: {
                issueType: string;
                phone: string;
                practiceName: string;
                systemCount: string;
                userName: string;
            };
        };
    };
}

// const initialState: IssueState = {
//     statusCode: 200,
//     message: '',
//     data: {
//         id: '',
//         issueCreatedAt: '',
//         description: '',
//         title: '',
//         user: {
//             email: '',
//             firstName: '',
//             lastName: '',
//         },
//         file: {
//             videoData: {
//                 data: new Blob(),
//             },
//             networkData: [],
//             systemInfoData: {
//                 info: [],
//                 speed: 0,
//             },
//             consoleLogData: [],
//             practiceInfo: {
//                 issueType: '',
//                 phone: '',
//                 practiceName: '',
//                 systemCount: '',
//                 userName: '',
//             },
//         },
//     },
// };
export const issueApiSlice = createApi({
    reducerPath: 'issue',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        prepareHeaders(headers) {
            return headers;
        },
        credentials: 'include',
    }),
    endpoints(builder) {
        return {
            fetchIssueAccess: builder.query({
                query: (id) => {
                    return `/issues/access/l1/${id}`;
                },
            }),
            fetchIssue: builder.query<IssueState, string | void>({
                query: (id) => {
                    return `/issues/l1/${id}`;
                },
            }),

            fetchBlobURL: builder.query({
                query: ({
                    fileName,
                    topLevelDomain,
                }: {
                    fileName: string;
                    topLevelDomain: string;
                }) => {
                    return `/issues/token/l1/readVideoUrl/${fileName}?topLevelDomain=${topLevelDomain}`;
                },
                transformResponse: (res: { data: { token: string } }) =>
                    res.data.token,
            }),

            fetchDayReports: builder.query({
                // todo tansform the data here for data.data
                query: (dates) => {
                    return `/files/dashboardIssueType?from=${dates?.fromDate}&to=${dates?.toDate}`;
                },
            }),
        };
    },
});

export const {
    useLazyFetchIssueQuery,
    useLazyFetchIssueAccessQuery,
    useFetchBlobURLQuery,
    useLazyFetchBlobURLQuery,
    useFetchDayReportsQuery,
} = issueApiSlice;
