import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select, { OptionProps } from 'react-select';

import {
    selectContainer,
    selectControl,
    selectMultiValue,
} from '../../../constants/style-contants';
import Avatar from '../../../containers/avatar/avatar';
import Button, { ButtonType } from '../../../containers/button';
import RadioButton from '../../../containers/radio-button';
import {
    useGetEmployeeRolesQuery,
    useLazyListUserQuery,
    useLazyPostShareIssueQuery,
} from '../../../features/user/userApiSlice';
import { useDebounce } from '../../../Hooks/useDebounce';
import { useAppSelector } from '../../../store/hooks';
import { optionStructure } from '../../../utils/common/index';
import { Employee } from '../../../utils/interfaces/email.model';
import { RoleEnum } from '../../../utils/interfaces/role.model';
import { ShareData } from '../../../utils/interfaces/share-data.model';
import { shareModalLocale } from '../../../utils/locale/shareMoadalLocale';

import styles from './style.module.css';

const ShareWindow = ({
    issueId,
    closeModal,
}: {
    issueId: any;
    closeModal: any;
}) => {
    const [selectedRadio, setSelectedRadio] = useState({
        l2: false,
        l3: false,
    });
    const [disableShare, setDisableShare] = useState(true);

    const userOptions = { roleIdKey: 4, limit: 500 };
    const [userCount, setUserCount] = useState(0);
    let offset = 1;

    const [selectedValues, setSelectedValues] = useState<number[]>([]);
    const [shareData, setShareData] = useState<ShareData>({
        emailIds: [],
        roles: [],
    });
    const debouncedSearchTerm = useDebounce('', 1000);

    const [shareIssue] = useLazyPostShareIssueQuery();
    const [getEmployess, { data: emailResponse }] = useLazyListUserQuery();
    const { data: rolesResponse } = useGetEmployeeRolesQuery();
    const user = useAppSelector((state) => state.user);

    const checkUserRoles = () => {
        return [RoleEnum.ADMIN, RoleEnum.L1].includes(user.role.roleEnum);
    };

    // 50 is the limit of users in a page.
    // userCount is the total count of users.
    // Math.ceil(userCount / 50) is done to get the maximum number of pages.

    // The loop will call the api for total number of page times.

    // Maximum L3 users in prod is close to 200.

    // Using this approach rather that the scroll is because, for searching we don't need to call that api again.
    // Searching will be handled by the react-select.

    useEffect(() => {
        if (userCount) {
            for (let i = 1; i < Math.ceil(userCount / 500); i++) {
                offset++;
                getEmployess({ ...userOptions, offset });
            }
        }
    }, [userCount]);

    useEffect(() => {
        debouncedSearchTerm !== null &&
            getEmployess({ ...userOptions, offset });
    }, [debouncedSearchTerm]);

    useEffect(() => {
        const isRadioSelected = Object.values(selectedRadio).some(
            (item) => item === true
        );
        if (selectedRadio.l2) {
            setDisableShare(false);
        } else {
            if (isRadioSelected && selectedValues.length) {
                setDisableShare(false);
            } else {
                setDisableShare(true);
            }
        }
    }, [selectedRadio, selectedValues]);

    useEffect(() => {
        if (emailResponse?.data?.data?.length) {
            if (emailResponse.data.data.length > 0) {
                const uniqueOptions = new Set(
                    [...shareData.emailIds, ...emailResponse.data.data].map(
                        (item) => JSON.stringify(item)
                    )
                );
                const uniqueOptionsArray = Array.from(uniqueOptions).map(
                    (item) => JSON.parse(item)
                );
                setShareData((items) => ({
                    ...items,
                    emailIds: uniqueOptionsArray,
                }));
                setUserCount(emailResponse.data.count);
            }
        }
        if (rolesResponse?.data?.data?.length) {
            if (rolesResponse.data.data.length > 0) {
                setShareData((items) => ({
                    ...items,
                    roles: rolesResponse.data.data,
                }));
            }
        }
    }, [emailResponse, rolesResponse]);

    const handleEmailChange = (changes: any) => {
        if (changes && changes.length) {
            const values = changes.map(
                ({ value }: { value: any }) => value.employeeId
            );
            setSelectedValues(values);
        } else {
            setSelectedValues([]);
        }
    };

    const handleShare = async () => {
        const postBody = {
            issueId,
            employees: selectedValues,
            role: selectedRadio.l2 ? 3 : 4,
        };
        const { isSuccess } = await shareIssue(postBody);
        if (isSuccess) {
            toast.success(shareModalLocale.toast.share_success_message);
            closeModal();
        } else {
            toast.error(shareModalLocale.toast.try_again_message);
        }
    };
    const emailOptions = optionStructure(shareData.emailIds, 'email');

    const customStyles = {
        container: (provided: any) => ({
            ...provided,
            ...selectContainer,
        }),
        control: (provided: any) => ({
            ...provided,
            ...selectControl,
        }),
        multiValue: (provided: any) => ({
            ...provided,
            ...selectMultiValue,
        }),
    };

    const customComponents = {
        Option: ({
            data,
            innerProps,
            isSelected,
        }: OptionProps<{ label: string; value: Employee }>) => {
            const {
                value: { email },
            } = data;
            return (
                <div
                    {...innerProps}
                    className={[
                        styles.optionContainer,
                        isSelected && styles.selected,
                    ].join(' ')}
                >
                    <div className={styles.optionWrapper}>
                        <Avatar initials={email.split('')?.[0] ?? ''} />
                        <div className={styles.optionItem}>{email}</div>
                    </div>
                </div>
            );
        },
    };
    const selectParams = {
        placeholder: shareModalLocale.dropdownPlaceholder,
        isClearable: true,
        options: emailOptions,
        onChange: handleEmailChange,
        styles: customStyles,
        isLoading: emailOptions && emailOptions.length == 0,
        components: customComponents,
        isMulti: true,
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.radioButtonMainContainer}>
                <div className={styles.contentHeading}>
                    <span>{shareModalLocale.radioGroupHeading}</span>
                </div>
                <div className={styles.radioButtonSubContainer}>
                    {checkUserRoles() && (
                        <div className={styles.radioButton}>
                            <RadioButton
                                checked={selectedRadio.l2}
                                label={shareModalLocale.l2SupportRadioLabel}
                                disabled={false}
                                name={shareModalLocale.l2SupprtRadioName}
                                onChange={() =>
                                    setSelectedRadio({
                                        l2: true,
                                        l3: false,
                                    })
                                }
                                dataQa="l2_radio_button"
                            />
                        </div>
                    )}
                    <div className={styles.radioButton}>
                        <RadioButton
                            checked={selectedRadio.l3}
                            label={shareModalLocale.l3SupportRadioLabel}
                            disabled={false}
                            name={shareModalLocale.l3SupprtRadioName}
                            onChange={() =>
                                setSelectedRadio({
                                    l2: false,
                                    l3: true,
                                })
                            }
                            dataQa="l3_radio_button"
                        />
                    </div>
                </div>
                {selectedRadio.l3 ? (
                    <div className={styles.dropdownContainerWrapper}>
                        <div className={styles.contentHeading}>
                            <span>{shareModalLocale.dropdownHeading}</span>
                            <span className={styles.asteriskStyle}>
                                {shareModalLocale.asterisk}
                            </span>
                        </div>
                        <div className={styles.dropdownContainer}>
                            <Select {...selectParams} />
                        </div>
                    </div>
                ) : null}
            </div>
            <div className={styles.footerButton}>
                <Button
                    type={ButtonType.neutral}
                    dataQa="CancelButton"
                    onClick={() => closeModal()}
                >
                    {shareModalLocale.cancelButton}
                </Button>
                <Button
                    type={
                        disableShare ? ButtonType.disabled : ButtonType.primary
                    }
                    dataQa="CancelButton"
                    onClick={() => handleShare()}
                    disabled={disableShare}
                >
                    {shareModalLocale.shareButton}
                </Button>
            </div>
        </div>
    );
};
export default ShareWindow;
