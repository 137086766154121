import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import React, { useNavigate, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import Loader from '../../components/Loader';
import { shareModalWrapper } from '../../constants/style-contants';
import Modal from '../../containers/modal';
import {
    useLazyFetchBlobURLQuery,
    useLazyFetchIssueQuery,
} from '../../features/issue/issueApiSlice';
import {
    progress,
    putGraphData,
} from '../../utils/issueDetails/issueDetailsUtils';
import { IssuePageLoacale as ILOCALE } from '../../utils/locale/issuePageLocale';
import { timeAdder } from '../../utils/timeFunction';

import ErrorPage from './ErrorPages/Unautorized';
import ShareWindow from './Share/index';
import { DataSourceType, LogType } from './issueDetails.types';
import MainContent from './MainContent';
import MetaDetails from './MetaDetails';
const IssueDetails = () => {
    const { issueId } = useParams();

    const [networkData, setNetworkData] = useState<Object[]>([]);
    const [consoleLogData, setConsoleLogData] = useState<Object[]>([]);
    const [systemInfoData, setSystemInfoData] = useState<Object[]>([]);
    const [combinedState, setCombinedState] = useState({
        network: null,
        console: null,
        system: {
            info: null,
            speed: null,
            ip: '',
            memory: {
                availableCapacity: 0,
                capacity: 0,
            },
        },
    });
    const [graphData, setGraphData] = useState<any>({});
    const [internetSpeed, setInternetSpeed] = useState(0);
    const [ipAddress, setIPAddress] = useState('');
    const [memory, setMemory] = useState({
        availableCapacity: 0,
        capacity: 0,
    });
    useEffect(() => {
        if (!ipAddress || !ipAddress.length) {
            setIPAddress('No Data');
        } else if (ipAddress === '::1') {
            setIPAddress('localhost');
        }
    }, [ipAddress]);

    const [activeTime, setActiveTime] = useState<{
        now: number;
        before: number;
    }>({ now: 0, before: 0 });
    const timerRef = useRef<any>();
    const navigate = useNavigate();
    const [getIssueDetails, { data: issueDetails, error, isFetching }] =
        useLazyFetchIssueQuery();
    const [getUrl] = useLazyFetchBlobURLQuery();
    const [fetchingDataSource, setFetchingDataSource] =
        useState<DataSourceType>('db');
    const [tab, setTab] = useState('network');
    const [metaTab, setMetaTab] = useState('bug');
    const [modalOpen, setModelOpen] = useState(false);

    const [networkDownloadStatus, setNetworkDownloadStatus] = useState<string>(
        ILOCALE.downloadingIssueLogs
    );
    const [consoleLogDownloadStatus, setConsoleLogDownloadStatus] =
        useState<string>(ILOCALE.downloadingIssueLogs);
    const [systemInfoDownloadStatus, setSystemInfoDownloadStatus] =
        useState<string>(ILOCALE.downloadingIssueLogs);

    //Fetch the blob from azure using the SAS token received from the backend
    const azureFileFetchFunction = (
        topLevelDomain: string,
        fileName: string,
        type: LogType,
        label: string
    ) => {
        getUrl({ fileName, topLevelDomain })
            .unwrap()
            .then((url) => {
                (async () => {
                    const response = await fetch(url);
                    const contentLength =
                        response.headers.get('content-length') || '';
                    const total = parseInt(contentLength, 10);
                    let loaded = 0;
                    const res = new Response(
                        new ReadableStream({
                            async start(controller) {
                                const reader = response.body?.getReader();
                                for (;;) {
                                    if (reader) {
                                        const { done, value } =
                                            await reader.read();
                                        if (done) break;
                                        loaded += value.byteLength;
                                        //Find the download progress of Network,Console log and System Info
                                        progress(
                                            loaded,
                                            total,
                                            type,
                                            setNetworkDownloadStatus,
                                            setConsoleLogDownloadStatus,
                                            setSystemInfoDownloadStatus
                                        );
                                        controller.enqueue(value);
                                    }
                                }
                                controller.close();
                            },
                        })
                    );
                    const blobText = await (await res.blob()).text();
                    const parsedText = JSON.parse(JSON.parse(blobText)) ?? []; //Parse the blob data
                    setCombinedState((item) => ({
                        ...item,
                        [label]: parsedText,
                    }));
                })();
            })
            .catch(() => {
                type === 'Network'
                    ? setNetworkDownloadStatus(ILOCALE.downloadingDataFailed)
                    : type === 'Console Log'
                        ? setConsoleLogDownloadStatus(ILOCALE.downloadingDataFailed)
                        : type === 'System Info'
                            ? setSystemInfoDownloadStatus(ILOCALE.downloadingDataFailed)
                            : null;
            });
    };

    useEffect(() => {
        getIssueDetails(issueId);
    }, [issueId]);

    useEffect(() => {
        if (issueDetails?.data) {
            const topLevelDomain = issueDetails?.data?.topLevelDomain;
            if (issueDetails?.statusCode === 403) {
                navigate('/', {
                    state: {
                        error: 'notAllowed',
                    },
                });
            } else if (
                issueDetails && //Check whether the response data is an array of objects or just an object with key as filename
                Array.isArray(issueDetails?.data?.file?.networkData) &&
                Array.isArray(issueDetails?.data?.file?.consoleLogData) &&
                'info' in issueDetails.data.file.systemInfoData
            ) {
                setFetchingDataSource('db');
                switchTabs('network');
                setNetworkData(
                    timeAdder(
                        issueDetails?.data?.file?.networkData,
                        'requesttime',
                        issueDetails?.data?.issueCreatedAt,
                        false
                    )
                );
                setConsoleLogData(
                    timeAdder(
                        issueDetails?.data?.file?.consoleLogData,
                        'timestamp',
                        issueDetails?.data?.issueCreatedAt,
                        true
                    )
                );
                setSystemInfoData(
                    timeAdder(
                        issueDetails?.data?.file?.systemInfoData?.info,
                        'timestamp',
                        issueDetails?.data?.issueCreatedAt,
                        true
                    )
                );
                setInternetSpeed(
                    issueDetails?.data?.file?.systemInfoData?.speed
                );
                setIPAddress(
                    issueDetails?.data?.file?.systemInfoData?.ip ?? ''
                );
            } else {
                setFetchingDataSource('azure');
                if ('fileName' in issueDetails.data.file.networkData) {
                    const networkFileName =
                        issueDetails?.data?.file?.networkData?.fileName ?? '';
                    azureFileFetchFunction(
                        topLevelDomain,
                        networkFileName,
                        'Network',
                        'network'
                    );
                }
                if ('fileName' in issueDetails.data.file.consoleLogData) {
                    const consoleLogDataFileName =
                        issueDetails?.data?.file?.consoleLogData?.fileName ??
                        '';
                    azureFileFetchFunction(
                        topLevelDomain,
                        consoleLogDataFileName,
                        'Console Log',
                        'console'
                    );
                }
                if ('fileName' in issueDetails.data.file.systemInfoData) {
                    const systemInfoDataFileName =
                        issueDetails?.data?.file?.systemInfoData?.fileName ??
                        '';
                    azureFileFetchFunction(
                        topLevelDomain,
                        systemInfoDataFileName,
                        'System Info',
                        'system'
                    );
                }
            }
        }
        if (error && (error as any).status === 404) {
            toast.error(ILOCALE.issueIdToastError, {
                duration: 2000,
            });
            navigate('/');
        }
    }, [issueDetails, error]);

    useEffect(() => {
        if (issueDetails?.data) {
            if (combinedState?.console) {
                setConsoleLogData(
                    timeAdder(
                        combinedState.console,
                        'timestamp',
                        issueDetails.data.issueCreatedAt,
                        true
                    )
                );
            }
            if (combinedState?.network) {
                switchTabs('network');
                setNetworkData(
                    timeAdder(
                        combinedState.network,
                        'requesttime',
                        issueDetails.data.issueCreatedAt,
                        false
                    )
                );
            }
            if (combinedState?.system?.info && combinedState?.system?.speed) {
                setSystemInfoData(
                    timeAdder(
                        combinedState.system.info,
                        'timestamp',
                        issueDetails.data.issueCreatedAt,
                        true
                    )
                );
                setInternetSpeed(combinedState.system.speed);
                setIPAddress(combinedState.system?.ip);
                setMemory(combinedState?.system?.memory);
            }
        }
    }, [combinedState]);

    const switchTabs = (nextTab: string) => {
        if (nextTab === 'system') {
            putGraphData(
                systemInfoData,
                setGraphData,
                timerRef,
                internetSpeed,
                ipAddress,
                memory
            );
        }
        setTab(nextTab);
    };

    const switchMetaTabs = (nextTab: string) => {
        setMetaTab(nextTab);
    };

    const onModalOpen = () => {
        setModelOpen(!modalOpen);
    };
    return (
        <>
            {isFetching && <Loader />}
            <Header isHome={false} />
            <>
                {error && (
                    <ErrorPage
                        status={(error as any).status}
                        message={(error as any).data.message}
                    />
                )}
            </>
            {issueDetails && !isFetching && !error && (
                <div className="app__content content">
                    <div className="path__wrapper">
                        <p className="text__link" onClick={() => navigate('/')}>
                            Home
                        </p>
                        &nbsp;&nbsp;/&nbsp;&nbsp;
                        <p className="id__wrapper">
                            Bug {issueDetails?.data.id}
                        </p>
                    </div>
                    <div className="subheader__block">
                        <div className="content__header">
                            <h2 className="title">
                                Review Reported Bug:{' #'}
                                {issueDetails?.data.id}
                            </h2>
                        </div>
                        <div className="share__block">
                            <button
                                className="btn primary"
                                onClick={onModalOpen}
                            >
                                Share Issue
                            </button>
                        </div>
                    </div>
                    <div className="content__body container">
                        <MainContent
                            switchMetaTabs={switchMetaTabs}
                            metaTab={metaTab}
                            data={issueDetails?.data}
                            setActiveTime={setActiveTime}
                        />
                        <MetaDetails
                            switchTabs={switchTabs}
                            tab={tab}
                            activeTime={activeTime}
                            graphData={graphData}
                            consoleLogData={consoleLogData}
                            networkData={networkData}
                            fetchingDataSource={fetchingDataSource}
                            consoleLogDownloadStatus={consoleLogDownloadStatus}
                            networkDownloadStatus={networkDownloadStatus}
                            systemInfoDownloadStatus={systemInfoDownloadStatus}
                            combinedState={combinedState}
                        />
                    </div>
                </div>
            )}
            <Modal
                open={modalOpen}
                setOpen={setModelOpen}
                styles={shareModalWrapper}
                title="Share Issue"
            >
                <ShareWindow issueId={issueId} closeModal={onModalOpen} />
            </Modal>
        </>
    );
};
export default IssueDetails;
