import { LogType } from '../../pages/IssueDetails/issueDetails.types';
import { IssuePageLoacale as ILOCALE } from '../locale/issuePageLocale';

/**
 *
 * @param systemInfoData
 * @param setGraphData
 * @param timerRef
 * @param internetSpeed
 *
 * sets the graph data and returns the idlePercentage ,kernalPercentagr, userPercentage and totalPercentage
 */
export const putGraphData = (
    systemInfoData: Object[],
    setGraphData: (value: any) => void,
    timerRef: React.MutableRefObject<any>,
    internetSpeed: number,
    ipAddress: string,
    memory: {
        availableCapacity: number;
        capacity: number;
    }
) => {
    setGraphData(() => {
        const gData: any = [];
        systemInfoData?.forEach((processData: any) => {
            let procData = {};
            let idlePercentage = 0;
            let kernalPercentage = 0;
            let userPercentage = 0;
            let totalPercentage = 0;
            // TODO: Consder using forEach here.
            processData?.processors?.every((proData: any, key: number) => {
                const {
                    idlePercentage: idle,
                    kernalPercentage: kernal,
                    userPercentage: user,
                    totalPercentage: total,
                } = formatSystemUsage(processData?.processors[key]?.usage);

                idlePercentage += idle;
                kernalPercentage += kernal;
                userPercentage += user;
                totalPercentage += total;
            });
            procData = {
                idlePercentage: idlePercentage,
                kernalPercentage: kernalPercentage,
                userPercentage: userPercentage,
                baseTime: processData.baseTime.toFixed(2),
                totalPercentage: totalPercentage,
            };
            gData.push(procData);
        });
        const usageData: { usage: string; baseTime: number }[] = [];
        gData?.every((dt: any, key: number) => {
            if (key + 1 === gData?.length - 1) {
                return false;
            }
            const diff =
                Math.abs(
                    gData[key + 1]?.userPercentage +
                        gData[key + 1]?.kernalPercentage -
                        (dt?.userPercentage + dt?.kernalPercentage)
                ) /
                Math.abs(gData[key + 1]?.totalPercentage - dt?.totalPercentage);
            usageData?.push({
                usage: (diff * 100).toFixed(2),
                baseTime:
                    (Number(gData[key + 1]?.baseTime) + Number(dt?.baseTime)) /
                    2,
            });
            return true;
        });
        if (systemInfoData[0] && internetSpeed) {
            const { archName, modelName, platform }: any = systemInfoData[0];
            return {
                usageData,
                archName,
                platform,
                modelName,
                internetSpeed,
                ipAddress,
                memory,
            };
        }
    });
    const formatSystemUsage = (syst: any) => {
        return {
            ...syst,
            idlePercentage: syst['idle'],
            kernalPercentage: syst['kernel'],
            userPercentage: syst['user'],
            totalPercentage: syst['total'],
        };
    };
    clearTimeout(timerRef.current);
    timerRef.current = null;
};

/**
 *
 * @param loaded
 * @param total
 * @param type
 * @param setDownloadStatus
 * @param downloadStatus
 *
 * Finds the downloaded percentage for each type(network log,system info,console log)
 */
export const progress = (
    loaded: number,
    total: number,
    type: LogType,
    setNetworkDownloadStatus: (value: string) => void,
    setConsoleLogDownloadStatus: (value: string) => void,
    setSystemInfoDownloadStatus: (value: string) => void
) => {
    if (total > 0) {
        type === 'Network'
            ? setNetworkDownloadStatus(
                ILOCALE.networkDownloadStatus +
                      Math.round((loaded / total) * 100) +
                      ILOCALE.percentage
            )
            : type === 'Console Log'
                ? setConsoleLogDownloadStatus(
                    ILOCALE.consoleLogDownloadStatus +
                      Math.round((loaded / total) * 100) +
                      ILOCALE.percentage
                )
                : type === 'System Info'
                    ? setSystemInfoDownloadStatus(
                        ILOCALE.systemInfoDownloadStatus +
                      Math.round((loaded / total) * 100) +
                      ILOCALE.percentage
                    )
                    : null;
    }
};
