import React, { useEffect, useRef, useState } from 'react';

import Header from '../../components/Header';
import Loader from '../../components/Loader';
import { useFetchDayReportsQuery } from '../../features/issue/issueApiSlice';

import { REPORT_LOCALE as R_LOCALE } from './reportsLocale';
import { ApiData, IssueTypes, ToolTipData } from './types';

import styles from './style.module.css';

const Reports = () => {

    const initialState = {
        'slowness': 0,
        'feature': 0,
        'unexpected': 0,
        'other': 0
    };

    const today = new Date();
    const [fromDate, setFromDate] = useState<string | undefined>((new Date(new Date().setDate(today.getDate() - 30))).toISOString().slice(0, 10));
    const [toDate, setToDate] = useState<string | undefined>(today.toISOString().slice(0, 10));
    const [dateWiseIssues, setDateWiseIssues] = useState([]);
    const [issueList, setIssueList] = useState<[ApiData] | []>([]);

    const [allIssuesByType, setAllIssuesByType] = useState<IssueTypes>(initialState);
    const [tooltipData, setTooltipData] = useState<ToolTipData>({ 'date': '', ...initialState });
    const fromDateRef = useRef<HTMLInputElement>(null);
    const toDateRef = useRef<HTMLInputElement>(null);

    const { data: apiResponse, error, isFetching } = useFetchDayReportsQuery({ fromDate, toDate });
    //const apiResponse = useFetchDayReportsQuery({fromDate, toDate});

    // this useEffect will be called when api will return the respose
    useEffect(() => {

        const apiData = apiResponse?.data;
        if (apiData) {
            // to show all the issues type wise
            setAllIssuesByType(groupIssuesByType(apiData));

            // setting main api data which will be reused from state
            setIssueList(apiData);
        }
    }, [apiResponse]);

    // this useEffect will be called after apiResponse is put into 'issueList' state
    useEffect(() => {
        let dateWiseIssue: any = {}; // any is used as dates as made as key of object

        // loop all the issues and group them date wise
        for (let i = 0; i < issueList.length; i++) {
            const currentIssueDate = issueList[i].date_;
            const issuesCount = dateWiseIssue[currentIssueDate];
            // if issue date repeates then increase the count otherwise set the date to currentIssueDate and count to 1
            if (issuesCount) {
                dateWiseIssue = { ...dateWiseIssue, [currentIssueDate]: issuesCount + 1 };

            } else {
                dateWiseIssue = { ...dateWiseIssue, [currentIssueDate]: 1 };
            }
        }
        // sort the issues by date before saving in state
        setDateWiseIssues(dateWiseIssue);
    }, [issueList]);

    // this function groups the issue by type
    const groupIssuesByType = (issues: ApiData[]) => {
        let issueCount = { ...initialState };

        for (let i = 0; i < issues.length; i++) {
            const pi: string = issues[i].practiceInfo;

            if (pi.includes('Feature')) {
                const currentCount = issueCount.feature;
                issueCount = { ...issueCount, feature: currentCount + 1 };
            } else if (pi.includes('Slowness')) {
                const present = issueCount.slowness;
                issueCount = { ...issueCount, slowness: present + 1 };
            } else if (pi.includes('Unexpected')) {
                const present = issueCount.unexpected;
                issueCount = { ...issueCount, unexpected: present + 1 };
            } else if (pi) {
                const present = issueCount.other;
                issueCount = { ...issueCount, other: present + 1 };
            }
        }
        return issueCount;
    };

    // this function will update the content of tooltip when cursor is hover over a issue
    const updateTooltipData = (date: string) => {
        const currentDayIssues: ApiData[] = issueList.filter((issue: ApiData) => issue?.date_ === date);
        const filteredIssues = groupIssuesByType(currentDayIssues);
        setTooltipData({ 'date': date, ...filteredIssues });
    };

    const fromDateHandler = (e: any) => {
        setFromDate(e.target.value);
    };

    const toDateHandler = (e: any) => {
        setToDate(e.target.value);
    };

    const prepareIssuesByDate = () => {
        const issueHTML = [];
        let index = 1;
        for (const issue in dateWiseIssues) {
            const htmlElement = <tr key={issue} onMouseEnter={() => { updateTooltipData(issue); }}>
                <td className={styles.td}>{index}</td>
                <td className={styles.td}>{issue}</td>
                <td className={styles.td}>
                    <div className={styles.tooltip}>
                        {dateWiseIssues[issue]}
                        <div id={`tooltip_popover_${index}`} className={`cfc-popover cfc-popover--regular  ${styles.tooltip_popover}`}>
                            <div className={styles.tooltip_popover__content}>
                                <div className={`cfc-popover__content-inner ${styles.tooltip_border}`}>
                                    <div className="cfc-popover__title">{R_LOCALE.DATE} {tooltipData?.date}</div>

                                    <div className={styles.issues_popover_count}>
                                        <div className={styles.issue_popover_name}>{R_LOCALE.issueTypes.SYSTEM_SLOW}</div>
                                        <div className='issue_popover_divider' />
                                        <div className='issue_popover_count'>{tooltipData?.slowness}</div>
                                    </div>

                                    <div className={styles.issues_popover_count}>
                                        <div className={styles.issue_popover_name}>{R_LOCALE.issueTypes.HOW_TO_USE_FEATURE}</div>
                                        <div className='issue_popover_divider' />
                                        <div className='issue_popover_count'>{tooltipData?.feature}</div>
                                    </div>

                                    <div className={styles.issues_popover_count}>
                                        <div className={styles.issue_popover_name}>{R_LOCALE.issueTypes.UNEXPECTED_BEHAVIOR}</div>
                                        <div className='issue_popover_divider' />
                                        <div className='issue_popover_count'>{tooltipData?.unexpected}</div>
                                    </div>

                                    <div className={styles.issues_popover_count}>
                                        <div className={styles.issue_popover_name}>{R_LOCALE.issueTypes.OTHER}</div>
                                        <div className='issue_popover_divider' />
                                        <div className='issue_popover_count'>{tooltipData?.other}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </td>
            </tr>;
            index++;
            issueHTML.push(htmlElement);
        }
        return issueHTML;
    };

    return (
        <div>
            {isFetching && <Loader />}

            <Header isHome={false} />

            <div className={styles.container}>
                <div className={styles.contentWrapper}>
                    <div className={styles.header}>

                        <h1>{R_LOCALE.common.DAYS_ISSUES_REPORT}</h1>

                        <div className={styles.dateSelect}>
                            <div className={styles.box}>
                                <span>{R_LOCALE.common.FROM}</span>
                                <input className={styles.calender} ref={fromDateRef} value={fromDate} type="date" onChange={fromDateHandler} />
                            </div>

                            <div className={styles.box}>
                                <span>{R_LOCALE.common.TO}</span>
                                <input className={styles.calender} ref={toDateRef} value={toDate} type="date" onChange={toDateHandler} />
                            </div>
                        </div>

                    </div>

                    <div className={styles.issuesTileWrapper}>
                        <div className={styles.total_tile}>
                            <div className={styles.total_tile_title}>{R_LOCALE.issueTypes.TOTAL_ISSUES_REPORTED}</div>
                            <div className={styles.total_tile_value}>{issueList.length}</div>
                        </div>

                        <div className={styles.issue_categories}>
                            <div className={styles.issue_sub_category}>
                                <div className={styles.total_tile_title}>{R_LOCALE.issueTypes.TOTAL_SLOWNESS_ISSUES}</div>
                                <div className={styles.total_tile_value}>{allIssuesByType?.slowness}</div>
                            </div>
                            <div className={styles.separator} />

                            <div className={styles.issue_sub_category}>
                                <div className={styles.total_tile_title}>{R_LOCALE.issueTypes.TOTAL_HOW_TO_USE_ISSUES}</div>
                                <div className={styles.total_tile_value}>{allIssuesByType?.feature}</div>
                            </div>
                            <div className={styles.separator} />

                            <div className={styles.issue_sub_category}>
                                <div className={styles.total_tile_title}>{R_LOCALE.issueTypes.TOTAL_UNEXPECTED_ISSUES}</div>
                                <div className={styles.total_tile_value}>{allIssuesByType?.unexpected}</div>
                            </div>
                            <div className={styles.separator} />

                            <div className={styles.issue_sub_category}>
                                <div className={styles.total_tile_title}>{R_LOCALE.issueTypes.TOTAL_OTHER_ISSUES}</div>
                                <div className={styles.total_tile_value}>{allIssuesByType?.other}</div>
                            </div>
                        </div>

                    </div>

                    <div className="cfc-table cfc-table--default">
                        {error && <span className={styles.error}>{R_LOCALE.common.ERROR}</span>}
                        <div className="cfc-table__head">
                            <table>
                                <thead>
                                    <tr>
                                        <th> <span className="cfc-table-header__label">{R_LOCALE.column.S_NO}</span> </th>
                                        <th> <span className="cfc-table-header__label">{R_LOCALE.column.DATE}</span> </th>
                                        <th> <span className="cfc-table-header__label">{R_LOCALE.column.ISSUES_REPORTED}</span> </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className={`cfc-table__body  ${styles.table__body}`}>
                            <table>
                                <tbody>
                                    {prepareIssuesByDate()}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <br />
                    <div className="cfc-alert cfc-alert--blue">
                        <span className="icon cfl-icon--info-outline"></span>
                        <div className="cfc-alert__text"><b>{R_LOCALE.common.INFO}</b></div>
                    </div>
                </div>
            </div>
        </div>);
};

export default Reports;
