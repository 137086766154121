import React, { useEffect, useState } from 'react';

import EmptyScreen from '../../../components/EmptyScreen';
import GridDrawer from '../../../components/GridDrawer';
import { fmtMSS } from '../../../utils/timeFunction';

import styles from './style.module.css';
const NetworkLogs = ({
    networkData,
    activieTime,
    seekVideoToPoint,
}: {
    networkData: object[];
    activieTime: any;
    seekVideoToPoint: Function;
}) => {
    const [requestDetails, setRequestDetails] = useState();
    const [showNetworkExpand, setShowNetworkExpand] = useState(false);
    const [zeroState, setZeroState] = useState(false);
    const [activeRow, setActiveRow] = useState(-1);
    const onNetworkExpandClick = (ntData: any, row: number) => {
        setActiveRow(row);
        seekVideoToPoint(ntData?.baseTime);
        setRequestDetails(ntData);
        setShowNetworkExpand(true);
    };
    const setGridExpand = (val: boolean) => {
        setShowNetworkExpand(val);
        setActiveRow(-1);
    };

    useEffect(() => {
        setZeroState(networkData?.length ? false : true);
        setGridExpand(false);
    }, [networkData]);

    return (
        <>
            {zeroState && <EmptyScreen type={'network'} />}
            {!zeroState && (
                <div className="panel__content network">
                    <div className="network__data grid">
                        <table className="grid__header">
                            <tbody>
                                <tr>
                                    <th className="time">Time</th>
                                    <th className="url">Url</th>
                                    <th className="status">Status</th>
                                    <th className="method">Method</th>
                                </tr>
                            </tbody>
                        </table>
                        <div className="grid__scroll">
                            <table className="grid__content">
                                <tbody>
                                    {networkData &&
                                        networkData.map(
                                            (ntData: any, index: number) => (
                                                <tr
                                                    key={index}
                                                    className={`${
                                                        activieTime?.now.toFixed(
                                                            0
                                                        ) ===
                                                            ntData?.baseTime.toFixed(
                                                                0
                                                            ) && 'bold__active'
                                                    } ${
                                                        index === activeRow &&
                                                        styles.activeRow
                                                    }`}
                                                    onClick={() =>
                                                        onNetworkExpandClick(
                                                            ntData,
                                                            index
                                                        )
                                                    }
                                                >
                                                    <td
                                                        className={`time ${
                                                            index === activeRow
                                                                ? styles.activeRow
                                                                : 'time__text'
                                                        }`}
                                                    >
                                                        {index ===
                                                            activeRow && (
                                                            <span>&#9654;</span>
                                                        )}
                                                        {fmtMSS(
                                                            ntData?.baseTime
                                                        )}
                                                    </td>
                                                    <>
                                                        <td
                                                            key={index}
                                                            className={`url ${
                                                                ntData?.statusCode ==
                                                                200
                                                                    ? ''
                                                                    : 'text-red'
                                                            } ${
                                                                index ===
                                                                    activeRow &&
                                                                styles.activeURL
                                                            }`}
                                                        >
                                                            {ntData?.url}
                                                        </td>
                                                    </>
                                                    <td
                                                        className={`status ${
                                                            ntData?.statusCode ==
                                                            200
                                                                ? 'text-green'
                                                                : 'text-red'
                                                        }`}
                                                    >
                                                        {ntData?.statusCode}
                                                    </td>
                                                    <td
                                                        className={`method ${
                                                            ntData?.statusCode ==
                                                            200
                                                                ? ''
                                                                : 'text-red'
                                                        }`}
                                                    >
                                                        {ntData?.method}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                </tbody>
                            </table>
                        </div>
                        {showNetworkExpand && requestDetails && (
                            <GridDrawer
                                setShowNetworkExpand={setGridExpand}
                                data={requestDetails}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default NetworkLogs;
