type SearchBarLocale = {
    errorMessages: {
        NOT_FOUND: string;
        FORBIDDEN: string;
        EXPIRED_TOKEN: string
    };
    searchPlaceholder: string;
};

export const searchBarLocale: SearchBarLocale = {
    errorMessages: {
        NOT_FOUND: 'Invalid Request ID',
        FORBIDDEN: 'You don\'t have access to this request yet.',
        EXPIRED_TOKEN: 'Session timed out'
    },
    searchPlaceholder: 'Search Request by ID',
};

export const previewLocale = {
    STARTING_DOWNLOAD: 'Starting Video Download...',
    DOWNLOADING_VIDEO:'Downloading Video : ',
    PERCENTAGE: '%',
};
