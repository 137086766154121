import React from 'react';
import { Toaster } from 'react-hot-toast';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { TOAST_OPTIONS } from './constants/toast-constants';
import AppRouter from './AppRouter';

import './App.css';
function App() {
    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_AUTH_CLIENT_ID || ''}>
            <Toaster toastOptions={TOAST_OPTIONS} />
            <AppRouter />
        </GoogleOAuthProvider>
    );
}

export default App;
