import React from 'react';

const EmptyScreen: React.FC<{ type: string }> = ({ type }) => {
    return (
        <React.Fragment>
            <div className="empty__block">{`No recorded ${type} data.`}</div>
        </React.Fragment>
    );
};

export default EmptyScreen;
