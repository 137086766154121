const shareModalWrapper = {
    color: 'black',
    minHeight: '20rem',
    minWidth: '48rem',
};

const selectContainer = {
    width: '54rem',
};
const selectControl = {
    backgroundColor: '#FFF',
};

const selectMultiValue = {
    backgroundColor: '#F7F8FA',
    border: '1px solid #D9DFE7',
    borderRadius: '0.4rem',
    maxWidth: '14rem',
};

export { selectContainer, selectControl, selectMultiValue, shareModalWrapper };
