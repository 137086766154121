import React, { useEffect, useState } from 'react';

import neutralSort from '../../assets/arrows-v.svg';
import searchIcon from '../../assets/search.svg';
import descSort from '../../assets/sort-amount-down.svg';
import ascSort from '../../assets/sort-amount-up.svg';
import { ReactComponent as CrossIcon } from '../../assets/x.svg';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Pagination from '../../components/pagination';
import {
    useGetEmployeeRolesQuery,
    useLazyListUserQuery,
} from '../../features/user/userApiSlice';
import {
    decrementPageNumber,
    incrementPageNumber,
    removeSort,
    setAvailableRoles,
    setEmailKey,
    setPageNumber,
    setRecordsPerPage,
    setRoleIdKey,
    setSort,
    setTotalRecods as setTotalRecords,
} from '../../features/user/userSlice';
import { useDebounce } from '../../Hooks/useDebounce';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import AddUser from './AddUser';
import RolesSelect from './RolesFilter';
import UserCard from './UserCard';

import styles from './styles.module.css';
const Users = () => {
    const [listUsers, { data: employees, isFetching }] = useLazyListUserQuery();
    const { data: roles, isLoading: isLoadingRoles } =
        useGetEmployeeRolesQuery();
    const {
        totalRecords,
        pageNumber,
        recordsPerPage,
        sortBy,
        sortOrder,
        emailKey,
        roleIdKey,
    } = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const debouncedSearchTerm = useDebounce(emailKey, 1000);
    const [addUser, setAddUser] = useState(false);
    const refreshData = () => {
        listUsers({
            limit: recordsPerPage,
            offset: pageNumber,
            sortBy,
            sortOrder,
            emailKey,
            roleIdKey,
        });
    };

    useEffect(() => {
        void refreshData();
    }, [pageNumber, sortOrder, recordsPerPage]);

    useEffect(() => {
        (debouncedSearchTerm !== null || roleIdKey !== null) && refreshData();
    }, [debouncedSearchTerm, roleIdKey]);

    useEffect(() => {
        if (employees && employees.data) {
            dispatch(setTotalRecords(employees.data.count));
        }
    }, [employees]);

    useEffect(() => {
        if (!isLoadingRoles && roles?.data.data)
            dispatch(setAvailableRoles(roles.data.data));
    }, [isLoadingRoles]);

    const handleSort = (label: string): void => {
        if (!(sortBy === label && sortOrder != null)) {
            dispatch(setSort({ sortBy: label, sortOrder: 'asc' }));
        } else if (sortBy === label && sortOrder === 'asc') {
            dispatch(setSort({ sortBy: label, sortOrder: 'desc' }));
        } else if (sortBy === label && sortOrder === 'desc') {
            dispatch(removeSort());
        }
    };

    const sortUI = (label: string) => {
        return (
            <div
                onClick={() => handleSort(label)}
                data-qa={'users_headers_' + label}
                className={styles['sort-users']}
                key={'users_headers_' + label}
            >
                {!(sortBy === label && sortOrder != null) && (
                    <img
                        data-qa={'users_headers_' + label + '_neutralSort'}
                        alt="sort-neutral"
                        width={16}
                        src={neutralSort}
                    />
                )}
                {sortBy === label && sortOrder === 'asc' && (
                    <img
                        data-qa={'users_headers_' + label + '_ascSort'}
                        alt="sort-asc"
                        className={styles['sort-active']}
                        width={16}
                        src={ascSort}
                    />
                )}
                {sortBy === label && sortOrder === 'desc' && (
                    <img
                        alt="sort-desc"
                        data-qa={'users_headers_' + label + '_descSort'}
                        className={styles['sort-active']}
                        width={16}
                        src={descSort}
                    />
                )}
            </div>
        );
    };
    const HandleAddUserSuccess = () => {
        refreshData();
        setAddUser(false);
    };

    return (
        <>
            <Header isHome={false} />
            <div className={styles.main}>
                <div className={styles.userHeader}>
                    <h1>All Users</h1>
                    <button
                        className={styles.addUserMain}
                        onClick={() => {
                            setAddUser(true);
                        }}
                        disabled={addUser}
                    >
                        Add New User
                    </button>
                </div>
                <div className={styles.usersGrid}>
                    <div className={styles.usersGridHeaders}>
                        <div className={styles.usersGridHeader}>
                            <div className={styles.heading}>
                                <span>Email</span>
                                <span className={styles.sortIcon}>
                                    {sortUI('email')}
                                </span>
                            </div>
                            <div className={styles.filter}>
                                <input
                                    className={styles.search_input}
                                    type="text"
                                    placeholder="Filter by user email"
                                    onChange={(e) =>
                                        dispatch(setEmailKey(e.target.value))
                                    }
                                    value={emailKey ?? ''}
                                />
                                {!emailKey && (
                                    <img
                                        className={styles.search_icon}
                                        src={searchIcon}
                                        alt="search"
                                    />
                                )}
                                {emailKey && (
                                    <CrossIcon
                                        stroke="#E4584C"
                                        className={[
                                            styles.search_icon,
                                            styles.pointer,
                                        ].join(' ')}
                                        onClick={() =>
                                            dispatch(setEmailKey(''))
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <div className={styles.usersGridHeader}>
                            <div className={styles.heading}>
                                <span>Role</span>
                                <span className={styles.sortIcon}>
                                    {sortUI('roleId')}
                                </span>
                            </div>
                            <div className={styles.filter}>
                                <RolesSelect
                                    name="role-filter"
                                    defaultOptionText="Filter by Role"
                                    isLoading={isLoadingRoles}
                                    customStyles={styles.rolesSelect}
                                    onChange={(option) => {
                                        if (option)
                                            dispatch(
                                                setRoleIdKey(option.value ?? '')
                                            );
                                    }}
                                    value={roleIdKey}
                                />
                                {roleIdKey && isFetching && (
                                    <div className={styles.spin}></div>
                                )}
                                {roleIdKey && !isFetching && (
                                    <button
                                        className={styles.svgButton}
                                        onClick={() => {
                                            dispatch(setRoleIdKey(''));
                                        }}
                                    >
                                        <CrossIcon
                                            stroke="#E4584C"
                                            className={styles.actions}
                                        />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    {addUser && !isLoadingRoles && roles && (
                        <div className={styles.animate}>
                            <AddUser
                                roles={roles.data.data}
                                onClose={() => setAddUser(false)}
                                onSuccess={HandleAddUserSuccess}
                            />
                        </div>
                    )}
                    <div
                        className={[
                            styles.data,
                            addUser ? styles.addOpen : styles.addClosed,
                        ].join(' ')}
                    >
                        {isFetching && <Loader></Loader>}
                        {employees &&
                            employees?.data.data.map(
                                (employee: any, index: number) => (
                                    <UserCard
                                        key={`user-${index}`}
                                        email={employee.email}
                                        employeeId={employee.employeeId}
                                        role={employee.role}
                                        onUpdate={refreshData}
                                        onDelete={refreshData}
                                    />
                                )
                            )}
                    </div>
                </div>
                <div className="pagination">
                    <Pagination
                        incrementPageNumber={() =>
                            dispatch(incrementPageNumber())
                        }
                        decrementPageNumber={() =>
                            dispatch(decrementPageNumber())
                        }
                        setPageNumber={(value: number) =>
                            dispatch(setPageNumber(value))
                        }
                        setRecordsPerPage={(value: number) =>
                            dispatch(setRecordsPerPage(value))
                        }
                        totalRecords={totalRecords}
                        pageNumber={pageNumber}
                        recordsPerPage={recordsPerPage}
                    />
                </div>
            </div>
        </>
    );
};
export default Users;
