import React, { useState } from 'react';

import logo from '../../assets/logo.svg';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import SearchBar from '../../components/SearchBar';
import { APPLICATION_NAME } from '../../constants';

import styles from './style.module.css';

const Landing = () => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <React.Fragment>
            {isLoading && <Loader />}
            <Header isHome />
            <div className={styles.landing__wrapper}>
                <img
                    src={logo}
                    alt="Carestack Logo"
                    className={styles.logo}
                />
                <p className={styles.application_name}>{APPLICATION_NAME}</p>
                <div className={styles.searchbar}>
                    <SearchBar
                        isLoading={setIsLoading}
                        customStyles={''}
                        isHome={true}
                    />
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};
export default Landing;
