import { ToastPosition } from 'react-hot-toast';

export const TOAST_OPTIONS = {
    className: '',
    style: {
        padding: '16px 20px',
        borderRadius: '4px',
        color: '#FFF',
    },
    success: {
        style: {
            background: 'green',
        },
    },
    error: {
        style: {
            background: '#B12519',
        },
        iconTheme: {
            primary: '#B12519',
            secondary: '#FFF',
        },
    },
    position: 'bottom-center' as ToastPosition,
};
