const displayVideo = (videoFile: Blob) => {
    if (!(videoFile instanceof Blob))
        throw new Error('`videoFile` must be a Blob or File object.'); // The `File` prototype extends the `Blob` prototype, so `instanceof Blob` works for both.

    const newObjectUrl = URL.createObjectURL(videoFile);

    return newObjectUrl;
};
const seekVideoToTime = (time: number, el: HTMLVideoElement | null) => {
    if (!el) return;
    if (!el.currentTime) {
        return;
    }
    el.currentTime = time;
};
export { displayVideo, seekVideoToTime };
