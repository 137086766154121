import { type AvatarProps, type ColorType } from './avatar.types';
import { AvatarTextColors } from './avatar-constants';

import AvatarStyles from './avatar.module.scss';

const Avatar: React.FC<AvatarProps> = ({ initials }: AvatarProps) => {
    const color: ColorType =
        AvatarTextColors[Math.floor(Math.random() * AvatarTextColors.length)];
    return (
        <div
            className={AvatarStyles['avatar-container']}
            style={{ ...(color && { backgroundColor: color.background }) }}
        >
            <span
                className={AvatarStyles['initials-text']}
                style={{ ...(color && { color: color.text }) }}
            >
                {initials}
            </span>
        </div>
    );
};

export default Avatar;
