import React, { useEffect, useState } from 'react';

import EmptyScreen from '../../../components/EmptyScreen';
import { fmtMSS } from '../../../utils/timeFunction';

const ConsoleLogs = ({
    consoleLogData,
    activieTime,
    seekVideoToPoint,
}: {
    consoleLogData: object[];
    activieTime: any;
    seekVideoToPoint: Function;
}) => {
    const [zeroState, setZeroState] = useState(false);

    useEffect(() => {
        setZeroState(
            !consoleLogData?.some(
                (item: any) => !isNaN(item?.baseTime) && item?.baseTime > 0
            )
        );
    }, [consoleLogData]);

    return (
        <>
            {zeroState && <EmptyScreen type={'console log'} />}
            {!zeroState && (
                <div className="network__data grid">
                    <table className="grid__header">
                        <tbody>
                            <tr>
                                <th className="time">Time</th>
                                <th className="status">Type</th>
                                <th className="url">Url</th>
                                <th className="url">Message</th>
                                <th className="status">source</th>
                            </tr>
                        </tbody>
                    </table>
                    <div className="grid__scroll">
                        <table className="grid__content">
                            <tbody>
                                {consoleLogData &&
                                    consoleLogData
                                        .filter((cD: any) => cD?.baseTime > 0)
                                        .map((ntData: any, index: number) => (
                                            <tr
                                                className={`${
                                                    activieTime.before <
                                                        ntData.baseTime &&
                                                    activieTime.now >
                                                        ntData.baseTime &&
                                                    'bold__active'
                                                }`}
                                                key={index}
                                                onClick={() =>
                                                    seekVideoToPoint(
                                                        ntData?.baseTime
                                                    )
                                                }
                                            >
                                                <td className="time time__text">
                                                    {fmtMSS(ntData?.baseTime)}
                                                </td>
                                                <td
                                                    className={`status ${
                                                        ntData?.level ==
                                                            'error' &&
                                                        'text-red'
                                                    }`}
                                                >
                                                    {ntData?.level}
                                                </td>
                                                <td
                                                    className={`url ${
                                                        ntData?.level ==
                                                            'error' &&
                                                        'text-red'
                                                    } `}
                                                >
                                                    {ntData.url}
                                                </td>
                                                <td
                                                    className={`url ${
                                                        ntData?.level ==
                                                            'error' &&
                                                        'text-red'
                                                    }`}
                                                >
                                                    {ntData.text}
                                                </td>
                                                <td
                                                    className={`status ${
                                                        ntData?.level ==
                                                            'error' &&
                                                        'text-red'
                                                    }`}
                                                >
                                                    {ntData?.source}
                                                </td>
                                            </tr>
                                        ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
};

export default ConsoleLogs;
