import { type RadioButtonProps } from './radio-buttonProps.types';

import RadioButtonStyles from '../../styles/components/form/field/radio-button.module.scss';

const RadioButton: React.FC<RadioButtonProps> = ({
    name,
    label,
    checked,
    onChange,
    disabled = false,
    dataQa,
}: RadioButtonProps) => {
    return (
        <label htmlFor={name} className={RadioButtonStyles['c-radio']}>
            <input
                className={RadioButtonStyles['c-radio__input']}
                type="radio"
                checked={checked}
                disabled={disabled}
                name={name}
                onChange={onChange}
            />
            <span
                className={RadioButtonStyles['c-radio__body']}
                onClick={onChange}
                tabIndex={-1}
                data-qa={dataQa}
                role="radio"
                onKeyDown={onChange}
                aria-checked={checked}
            />
            <div
                className={RadioButtonStyles['c-radio__text']}
                onClick={onChange}
                role="button"
                onKeyDown={onChange}
                tabIndex={-1}
                data-qa={`${dataQa}_label`}
            >
                {label}
            </div>
        </label>
    );
};

export default RadioButton;
