import React from 'react';

interface InputProps {
    title: string;
    value: string;
}

const TextField: React.FC<InputProps> = ({ title, value }) => {
    return (
        <>
            <p className="meta__title">{title}</p>:
            <p className="meta__value">{value}</p>
        </>
    );
};

export default TextField;
