type LocalStorageKeys = 'path';

export const getItem = (key: LocalStorageKeys): string | null =>
    localStorage.getItem(key);

export const setItem = (key: LocalStorageKeys, value: string): void =>
    localStorage.setItem(key, value);

export const removeItem = (key: LocalStorageKeys): void =>
    localStorage.removeItem(key);
