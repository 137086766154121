import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';

import { updateAuthentication } from '../../features/user/userSlice';
import { useAppSelector } from '../../store/hooks';
import { getItem, removeItem } from '../../utils/common/local-storage.index';
import { getCookie, setCookie } from '../../utils/cookies';

let reRender = false;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GoogleSignInButton = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const googleAuthCookie = getCookie('google-auth');

    const user = useAppSelector((state) => state.user);

    const onSuccess = (res: CredentialResponse) => {
        setCookie('google-auth', res.credential || '');
        const decodedToken: any = jwt_decode(res.credential || '');
        setCookie('profile', decodedToken);
        dispatch(updateAuthentication(true));
        const path = getItem('path') ?? '/';
        navigate(path);
    };

    const onFailure = () => {
        navigate('/buglogin');
    };

    useEffect(() => {
        const path = getItem('path') ?? '/';
        if (user.isAuthenticated && !reRender) {
            navigate(path);
            reRender = true;
            removeItem('path');
        }
    }, [user.isAuthenticated]);

    useEffect(() => {
        if (!googleAuthCookie) {
            navigate('/buglogin');
        }
    }, [googleAuthCookie, navigate]);

    return <GoogleLogin onSuccess={onSuccess} onError={onFailure} />;
};

export default GoogleSignInButton;
