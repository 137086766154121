import { Employee } from '../interfaces/email.model';

const throttle = (func: Function, timeFrame: number) => {
    let lastTime = 0;
    return function () {
        const now = Date.now();
        if (now - lastTime >= timeFrame) {
            func();
            lastTime = now;
        }
    };
};

const formatDateAndTime = (date: Date) => {
    const options: any = {
        year: 'numeric',
        month: 'long',
        weekday: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
    };
    return new Date(Number(date)).toLocaleDateString('en-US', options);
};
function isJsonString(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

type RoleId = {
    roleId: number;
    roleName: string;
};
// TODO: Make types for this function and make generic.
const optionStructure = (dataArray: any, type: string) => {
    if (dataArray && dataArray.length) {
        if (type === 'email') {
            return dataArray
                .map((item: Employee) => ({
                    value: item,
                    label: item?.email,
                }));
        }
        return dataArray.map((item: RoleId) => ({
            value: item.roleId,
            label: item.roleName,
        }));
    }
    return [];
};
const optionFilter = (dataArray: any, stringCondition: string) => {
    return (
        (dataArray &&
            dataArray.length &&
            dataArray.find((item: any) => item.email === stringCondition)) ||
        {}
    );
};

export {
    formatDateAndTime,
    isJsonString,
    optionFilter,
    optionStructure,
    throttle,
};
