import React, { CSSProperties, MouseEventHandler } from 'react';

import Button, { ButtonType } from '../button';
import ButtonGroup, { ButtonGroupAlignment } from '../button-group';

import '../../styles/components/form/form-group.scss';
import modalStyles from '../../styles/components/modal.module.scss';
export interface ModalProps {
  title: string;
  text: string;
  style?: CSSProperties;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  onConfirm?: MouseEventHandler<HTMLButtonElement>;
  onClose?: MouseEventHandler<HTMLAnchorElement>;
  isErrorTitle?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmStyle?: string;
  cancelStyle?: string;
  confirmLoading?: boolean;
}

const ModalConfirmation: React.FC<ModalProps> = ({
    title,
    text,
    onCancel,
    onConfirm,
    onClose,
    isErrorTitle = false,
    confirmLabel = 'Confirm',
    cancelLabel = 'Cancel',
    confirmStyle = '',
    cancelStyle = '',
    confirmLoading = false,
    ...args
}) => {
    return (
        <div
            className={`${modalStyles['c-modal']} ${modalStyles['c-modal--confirmation']} ${modalStyles['is-active']}`}
            {...args}
        >
            <div className={modalStyles['c-modal__container']}>
                <div className={modalStyles['c-modal__header']}>
                    <h2
                        className={
                            !isErrorTitle
                                ? modalStyles['c-modal__title']
                                : (modalStyles['c-modal__title'],
                                modalStyles['c-modal__title--red'])
                        }
                    >
                        {title}
                    </h2>
                    {onClose && (
                        <a className={modalStyles['c-modal__close']} onClick={onClose}>
                            <span className="c-icon--times">X</span>
                        </a>
                    )}
                </div>
                <div className={modalStyles['c-modal__content']}>
                    <p className={modalStyles['c-modal__text']}>{text}</p>
                    <ButtonGroup alignment={ButtonGroupAlignment.right}>
                        {onCancel && (
                            <Button
                                styles={cancelStyle}
                                dataQa="modal_confirmation_cancel"
                                type={ButtonType.secondary}
                                onClick={onCancel}
                            >
                                {cancelLabel}
                            </Button>
                        )}
                        {onConfirm && (
                            <Button
                                styles={confirmStyle}
                                dataQa="modal_confirmation_confirm"
                                type={ButtonType.primary}
                                onClick={onConfirm}
                            >
                                {!confirmLoading && confirmLabel}
                                {confirmLoading && <div className="spin" />}
                            </Button>
                        )}
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
};
export default ModalConfirmation;
