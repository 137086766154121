import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';

import arrowDownIcon from '../../../assets/arrowdown.svg';
import arrowUpIcon from '../../../assets/arrowup.svg';
import chartIcon from '../../../assets/chart-line.svg';
import logoutIcon from '../../../assets/logout.svg';
import userIcon from '../../../assets/user.svg';
import { useLazyLogoutUserSessionQuery } from '../../../features/user/userApiSlice';
import { clear } from '../../../features/user/userSlice';
import { useOutsideClickAlert } from '../../../Hooks/useOutsideClickAlert';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { deleteAllCookies } from '../../../utils/cookies';

import styles from './style.module.css';

const RightMenu = () => {
    const [
        clearSession,
        { isSuccess: loginSuccess, data: loginSuccessStatus },
    ] = useLazyLogoutUserSessionQuery();
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const [menuOpen, setMenuOpen] = useState(false);
    const profileMenuRef: any = useRef();
    const onMenuOpen = () => {
        setMenuOpen(!menuOpen);
    };
    const menuClose = () => {
        setMenuOpen(false);
    };
    const onLogoutClick = async () => {
        clearSession();
        dispatch(clear());
        googleLogout();
        deleteAllCookies();
    };
    const navigate = useNavigate();
    const goToUsers = () => {
        navigate('/users');
    };

    const goToReports = () => {
        navigate('/reports');
    };

    useEffect(() => {
        if (loginSuccessStatus) {
            navigate('/buglogin');
            window.location.reload();
        }
    }, [loginSuccessStatus, loginSuccess]);
    const location = useLocation();
    const checkUserRoles = () => {
        return user?.role?.roleId === 1;
    };
    useOutsideClickAlert({ ref: profileMenuRef, onOutsideClick: menuClose });
    return (
        <>
            <div
                ref={profileMenuRef}
                className={styles.proPicHolder}
                onClick={onMenuOpen}
            >
                {user?.profileObject?.picture && (
                    <img
                        className={styles.proPic}
                        src={user.profileObject.picture ?? ''}
                        alt="pro-pic"
                        referrerPolicy="no-referrer"
                    />
                )}
                <div className={styles.name}>{user.profileObject.name}</div>
                {!menuOpen ? (
                    <img src={arrowDownIcon} alt="" />
                ) : (
                    <img src={arrowUpIcon} alt="" />
                )}
                {menuOpen === true && (
                    <div className={styles.proMenu}>
                        {location.pathname !== '/users' && checkUserRoles() && (
                            <div
                                onClick={goToUsers}
                                className={styles.menuItem}
                            >
                                <div className={styles.usericon}>
                                    <img src={userIcon} alt="" />
                                </div>
                                All Users
                            </div>
                        )}
                        {location.pathname !== '/reports' && checkUserRoles() && (
                            <div
                                onClick={goToReports}
                                className={styles.menuItem}
                            >
                                <div className={styles.usericon}>
                                    <img src={chartIcon} alt="" />
                                </div>
                                    Reports
                            </div>
                        )}
                        <div
                            onClick={onLogoutClick}
                            className={styles.menuItem}
                        >
                            <div className={styles.logouticon}>
                                <img src={logoutIcon} alt="" />
                            </div>
                            Logout
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default RightMenu;
