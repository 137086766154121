import React, { useMemo } from 'react';
import Select, {
    ActionMeta,
    CSSObjectWithLabel,
    SingleValue,
} from 'react-select';

import { useAppSelector } from '../../../store/hooks';
import { toTitleCase } from '../../../utils/string';

const RolesSelect = ({
    value,
    onChange,
    customStyles,
    defaultOptionText,
    name,
    isLoading,
    errorState,
}: {
    name: string;
    value: string | number | null;
    onChange: (
        newValue: SingleValue<{ label: string; value: string }>,
        actionMeta: ActionMeta<{ label: string; value: string }>
    ) => void;
    customStyles?: string;
    defaultOptionText: string;
    isLoading?: boolean;
    errorState?: boolean;
}) => {
    const roles = useAppSelector((state) => state.user.availableRoles);
    const options = useMemo(
        () =>
            roles.map((role) => ({
                label: toTitleCase(role.roleName),
                value: role.roleId.toString(),
                name,
            })),
        [roles, name]
    );

    const defaultValue = { label: defaultOptionText, value: '' };
    const borderStyle = {
        control: (base: CSSObjectWithLabel) => ({
            ...base,
            borderColor: errorState ? '#e4584c' : base.borderColor,
            cursor: 'pointer',
        }),
        menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }),
        option: (base: CSSObjectWithLabel) => ({
            ...base,
            cursor: 'pointer',
        }),
    };

    return (
        <Select
            className={customStyles}
            options={options}
            isLoading={isLoading}
            name={name}
            onChange={onChange}
            defaultValue={defaultValue}
            styles={borderStyle}
            menuPortalTarget={document.body}
            value={
                value
                    ? options.find((option) => +option.value === +value)
                    : defaultValue
            }
        />
    );
};

export default RolesSelect;
