import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { ReactComponent as CheckIcon } from '../../../assets/check.svg';
import { ReactComponent as CrossIcon } from '../../../assets/x.svg';
import { useLazyCreateUserQuery } from '../../../features/user/userApiSlice';
import { Role } from '../../../utils/interfaces/role.model';
import { loginPageLocale } from '../../../utils/locale/loginPageLocale';
import { validateEmail } from '../../../utils/string';
import RolesSelect from '../RolesFilter';

import styles from './style.module.css';

const AddUser = ({
    onClose,
    onSuccess,
}: {
    onClose: () => void;
    onSuccess: () => void;
    roles: Role[];
}) => {
    type FormError = { [key: string]: string };
    type FormState = {
        email: {
            value: string;
            errors: FormError;
            errorState: boolean;
        };
        roleId: {
            value: number;
            errors: FormError;
            errorState: boolean;
        };
    };
    const initialFormState: FormState = {
        email: {
            value: '',
            errors: {},
            errorState: false,
        },
        roleId: {
            value: 0,
            errors: {},
            errorState: false,
        },
    };
    const [
        createUser,
        { data: userCreated, isSuccess, isError, error, isLoading },
    ] = useLazyCreateUserQuery();
    const [form, setForm] = useState<FormState>(initialFormState);

    const onFormDataChange = (e: any) => {
        const { name, value } = e.target;

        setForm((prev) => {
            return {
                ...prev,
                [name]: { errors: {}, value, errorState: false },
            };
        });
    };

    const validateForm = (form: FormState) => {
        let errorFlag = true;
        if (!form.email.value) {
            setForm((prev) => ({
                ...prev,
                email: {
                    ...prev.email,
                    errors: {
                        ...prev.email.errors,
                        noEmail: 'Please enter user email',
                    },
                    errorState: true,
                },
            }));
            errorFlag = false;
        }

        if (form.email.value && !validateEmail(form.email.value)) {
            setForm((prev) => ({
                ...prev,
                email: {
                    ...prev.email,
                    errors: {
                        ...prev.email.errors,
                        invalidEmail: 'Please enter valid email',
                    },
                    errorState: true,
                },
            }));
            toast.error('Please enter valid email');

            errorFlag = false;
        }

        if (!form.roleId.value) {
            setForm((prev) => ({
                ...prev,
                roleId: {
                    ...prev.roleId,
                    errors: {
                        ...prev.roleId.errors,
                        invalidEmail: 'Please select user role',
                    },
                    errorState: true,
                },
            }));
            errorFlag = false;
        }

        return errorFlag;
    };
    const saveData = () => {
        if (validateForm(form)) {
            const { email, roleId } = form;
            createUser({ email: email.value, roleId: roleId.value });
        }
    };

    useEffect(() => {
        if (userCreated) {
            toast.success('User Inserted Successfully');
            setForm(() => initialFormState);
            onSuccess();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            const errorMsg: any = error;
            if (errorMsg.data.message === 'Employee already exists') {
                toast.error(`${form.email.value} already exists`);
                setForm((prev) => {
                    return {
                        ...prev,
                        email: {
                            ...prev.email,
                            errorState: true,
                            errors: {
                                'Already Exists': `${form.email.value} already exists`,
                            },
                        },
                    };
                });
            } else if (errorMsg.data.error === 'Unauthorized') {
                toast.error(loginPageLocale.token_expired_please_login_again);
            } else {
                toast.error(errorMsg.data.message || loginPageLocale.toast.something_went_wrong);
            }
        }
    }, [isError, error]);

    const printErrors = (errors: FormError) => {
        return (
            <>
                {Object.keys(errors).map((error, index) => {
                    return (
                        <span key={`error-${index + 1}`}>{errors[error]}</span>
                    );
                })}
            </>
        );
    };
    return (
        <>
            <div
                className={[
                    styles.row,
                    (form.email.errorState || form.roleId.errorState) &&
                        styles.errorMargin,
                ].join(' ')}
            >
                <div className={styles.col}>
                    <div style={{ position: 'relative' }}>
                        <input
                            type="text"
                            name="email"
                            value={form.email.value ?? ''}
                            placeholder="Enter user email address"
                            onChange={(e) => onFormDataChange(e)}
                            className={[
                                styles.emailInput,
                                form.email.errorState ? styles.errorInput : '',
                            ].join(' ')}
                        />
                        <div className={[styles.errorStyle].join(' ')}>
                            {printErrors(form.email.errors)}
                        </div>
                    </div>
                </div>
                <div className={[styles.col, styles.colWithAction].join(' ')}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <RolesSelect
                            name="roleId"
                            value={form.roleId.value ?? 0}
                            onChange={(e) => {
                                onFormDataChange({ target: e });
                            }}
                            customStyles={styles.selectInput}
                            errorState={form.roleId.errorState}
                            defaultOptionText="Select User Role"
                        />

                        <div className={styles.errorStyle}>
                            {printErrors(form.roleId.errors)}
                        </div>
                    </div>
                    {!isLoading && (
                        <button className={styles.svgButton} onClick={saveData}>
                            <CheckIcon
                                stroke="#199958"
                                className={[
                                    styles.actions,
                                    (form.email.errorState ||
                                        form.roleId.errorState) &&
                                        styles.disabled,
                                ].join(' ')}
                            />
                        </button>
                    )}
                    {isLoading && <div className={styles.spin}></div>}
                    <button className={styles.svgButton} onClick={onClose}>
                        <CrossIcon
                            stroke="#E4584C"
                            className={[styles.actions].join(' ')}
                        />
                    </button>
                </div>
            </div>
        </>
    );
};

export default AddUser;
