import React, { CSSProperties } from 'react';

import closeIcon from '../../assets/close.svg';

import styles from './style.module.css';
const Modal: React.FC<{
    children: JSX.Element | JSX.Element[] | string | string[];
    styles?: CSSProperties;
    setOpen: Function;
    backDropClose?: boolean;
    open: boolean;
    title?: string;
}> = ({
    children,
    styles: customStyles,
    setOpen,
    open,
    backDropClose = false,
    title,
}) => {
    const onBackDropClick = (e: any) => {
        if (backDropClose) {
            closeModal();
        } else {
            e.preventDefault();
        }
    };
    const closeModal = () => {
        setOpen(false);
    };
    return (
        <>
            {open && (
                <div className={styles.main} onClick={onBackDropClick}>
                    <div className={styles.content} style={customStyles}>
                        <div className={styles.headerWrapper}>
                            <div className={styles.modalHeader}>
                                <div className={styles.modalTitle}>{title}</div>
                                <img
                                    onClick={closeModal}
                                    className={styles.closeButton}
                                    src={closeIcon}
                                    alt="Close Drawer"
                                />
                            </div>
                        </div>
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
