import { configureStore } from '@reduxjs/toolkit';

import { issueApiSlice } from '../features/issue/issueApiSlice';
import { userApiSlice } from '../features/user/userApiSlice';
import userReducer from '../features/user/userSlice';
export const store = configureStore({
    reducer: {
        [issueApiSlice.reducerPath]: issueApiSlice.reducer,
        [userApiSlice.reducerPath]: userApiSlice.reducer,
        user: userReducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat([
            issueApiSlice.middleware,
            userApiSlice.middleware,
        ]);
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
