import React, { useEffect } from 'react';

import { seekVideoToTime } from '../../../utils/video';
import ConsoleLogs from '../ConsoleLogs';
import { DataSourceType, setCombinedState } from '../issueDetails.types';
import NetworkLogs from '../NetworkLogs';
import SystemLogs from '../SystemLogs';
const MetaDetails = ({
    switchTabs,
    tab,
    activeTime,
    graphData,
    consoleLogData,
    networkData,
    systemInfoDownloadStatus,
    consoleLogDownloadStatus,
    networkDownloadStatus,
    fetchingDataSource,

    combinedState,
}: {
    switchTabs: Function;
    tab: string;
    activeTime: {
        now: number;
        before: number;
    };
    graphData: any;
    consoleLogData: any;
    networkData: any;
    fetchingDataSource: DataSourceType;
    systemInfoDownloadStatus: string;
    consoleLogDownloadStatus: string;
    networkDownloadStatus: string;

    combinedState: setCombinedState;
}) => {
    useEffect(() => {
        if (graphData && graphData.usageData) {
            graphData.usageData.map(
                (data: { usage: string; baseTime: number }, index: number) => {
                    if (data && data.baseTime) {
                        graphData.usageData[index].usage = parseInt(data.usage);
                        graphData.usageData[index].baseTime = parseInt(
                            data.baseTime.toFixed(0)
                        );
                    }
                }
            );
        }
    }, [graphData]);

    const seekVideoToPoint = (time: number) => {
        seekVideoToTime(
            time,
            document.getElementById('feed') as HTMLVideoElement | null
        );
    };
    return (
        <div className="container__right panel">
            <div className="panel__header">
                <div className="tab">
                    <a
                        onClick={() => switchTabs('network')}
                        className={`tab__button ${
                            tab == 'network' && 'selected'
                        }`}
                    >
                        Network
                    </a>
                    <a
                        onClick={() => switchTabs('console')}
                        className={`tab__button ${
                            tab == 'console' && 'selected'
                        }`}
                    >
                        Console Logs
                    </a>
                    <a
                        onClick={() => switchTabs('system')}
                        className={`tab__button ${
                            tab == 'system' && 'selected'
                        }`}
                    >
                        System Info
                    </a>
                </div>
            </div>
            {tab === 'network' ? (
                fetchingDataSource === 'azure' ? (
                    !combinedState?.network ? (
                        <div className="downloading-status">
                            {networkDownloadStatus}
                        </div>
                    ) : (
                        <NetworkLogs
                            activieTime={activeTime}
                            networkData={networkData}
                            seekVideoToPoint={seekVideoToPoint}
                        />
                    )
                ) : (
                    <NetworkLogs
                        activieTime={activeTime}
                        networkData={networkData}
                        seekVideoToPoint={seekVideoToPoint}
                    />
                )
            ) : null}
            {tab === 'console' ? (
                fetchingDataSource === 'azure' ? (
                    !combinedState?.console ? (
                        <div className="downloading-status">
                            {consoleLogDownloadStatus}
                        </div>
                    ) : (
                        <ConsoleLogs
                            activieTime={activeTime}
                            consoleLogData={consoleLogData}
                            seekVideoToPoint={seekVideoToPoint}
                        />
                    )
                ) : (
                    <ConsoleLogs
                        activieTime={activeTime}
                        consoleLogData={consoleLogData}
                        seekVideoToPoint={seekVideoToPoint}
                    />
                )
            ) : null}
            {tab === 'system' ? (
                fetchingDataSource === 'azure' ? (
                    !combinedState?.system?.info ? (
                        <div className="downloading-status">
                            {systemInfoDownloadStatus}
                        </div>
                    ) : (
                        <SystemLogs
                            activieTime={activeTime}
                            graphData={graphData}
                            seekVideoToPoint={seekVideoToPoint}
                        />
                    )
                ) : (
                    <SystemLogs
                        activieTime={activeTime}
                        graphData={graphData}
                        seekVideoToPoint={seekVideoToPoint}
                    />
                )
            ) : null}
        </div>
    );
};
export default MetaDetails;
