import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCookie } from '../../utils/cookies';
import { Role } from '../../utils/interfaces/role.model';

interface UserState {
    isAuthenticated: boolean;
    loading: boolean;
    profileObject: ProfileObject;
    role: Role;
    availableRoles: Role[];
    totalRecords: number;
    recordsPerPage: number;
    pageNumber: number;
    sortOrder: string | null;
    sortBy: string | null;
    emailKey: string | null;
    roleIdKey: string | null;
    status: 'SUCCESS' | 'ERROR' | null;
}
interface ProfileObject {
    email: string;
    familyName: string;
    givenName: string;
    googleId: string;
    picture: string;
    name: string;
}
export interface SortInput {
    sortOrder: string | null;
    sortBy: string | null;
}
const initialState: UserState = {
    isAuthenticated: !!getCookie('google-auth'),
    loading: true,
    profileObject: {
        email: '',
        familyName: '',
        givenName: '',
        googleId: '',
        picture: '',
        name: '',
    },
    role: {
        roleName: '',
        roleId: 0,
        roleEnum: 0,
    },
    availableRoles: [],
    totalRecords: 0,
    recordsPerPage: 10,
    pageNumber: 1,
    sortOrder: null,
    sortBy: null,
    emailKey: null,
    roleIdKey: null,
    status: null,
};
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        add(state, action: PayloadAction<Partial<UserState>>) {
            return { ...state, ...action.payload, loading: false };
        },
        clear(state) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state = { ...initialState, isAuthenticated: false };
        },
        updateAuthentication(state, action: PayloadAction<boolean>) {
            state.isAuthenticated = action.payload;
        },
        incrementPageNumber: (state) => {
            if (state.pageNumber * state.recordsPerPage < state.totalRecords) {
                state.pageNumber += 1;
            }
        },
        decrementPageNumber: (state) => {
            if (state.pageNumber > 1) {
                state.pageNumber -= 1;
            }
        },
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload;
        },
        setTotalRecods: (state, action: PayloadAction<number>) => {
            state.totalRecords = action.payload;
        },
        setRecordsPerPage: (state, action: PayloadAction<number>) => {
            state.recordsPerPage = action.payload;
            state.pageNumber = 1;
        },
        setSort: (state, action: PayloadAction<SortInput>) => {
            state.pageNumber = 1;
            state.sortBy = action.payload.sortBy;
            state.sortOrder = action.payload.sortOrder;
        },
        removeSort: (state) => {
            state.pageNumber = 1;
            state.sortBy = null;
            state.sortOrder = null;
        },
        resetUserTable: (state) => {
            state.sortBy = null;
            state.sortOrder = null;
            state.pageNumber = 1;
        },
        setEmailKey: (state, action: PayloadAction<string>) => {
            state.pageNumber = 1;
            state.emailKey = action.payload;
        },
        setRoleIdKey: (state, action: PayloadAction<string>) => {
            state.pageNumber = 1;
            state.roleIdKey = action.payload;
        },
        setAvailableRoles: (state, action: PayloadAction<Role[]>) => {
            state.availableRoles = action.payload;
        },
    },
});

export const {
    add,
    clear,
    decrementPageNumber,
    incrementPageNumber,
    removeSort,
    resetUserTable,
    setPageNumber,
    setEmailKey,
    setRoleIdKey,
    setRecordsPerPage,
    setSort,
    updateAuthentication,
    setTotalRecods,
    setAvailableRoles,
} = userSlice.actions;
export default userSlice.reducer;
