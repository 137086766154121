export const REPORT_LOCALE = {
    issueTypes : {
        SYSTEM_SLOW : 'Software Slowness:',
        HOW_TO_USE_FEATURE: 'How to Use a Feature: ',
        UNEXPECTED_BEHAVIOR: 'Unexpected Behavior/ System Errors: ',
        OTHER: 'Other Issues',
        TOTAL_ISSUES_REPORTED: 'Total Issues Reported:',
        TOTAL_SLOWNESS_ISSUES: 'Software Slowness:',
        TOTAL_HOW_TO_USE_ISSUES: 'How to use a Feature:',
        TOTAL_UNEXPECTED_ISSUES: 'Unexpected Behavior/ System Error:',
        TOTAL_OTHER_ISSUES: '"Other" Issues:',
        
    },

    DATE: 'Date:',

    common: {
        DAYS_ISSUES_REPORT: 'Reported Issues',
        INFO: '* Hover over Issues Reported to see types of issues reported on that day',
        SUBMIT: 'Submit',
        FROM: 'From Date',
        TO: 'To Date',
        ERROR: 'Error while fetching records'
    },
    
    column: {
        DATE: 'Issue Reported Date',
        S_NO: 'S.No.',
        ISSUES_REPORTED: 'Issues Reported',
    }
};