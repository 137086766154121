import React, { FC, useEffect, useState } from 'react';

import angleDoubleLeftIcon from '../../assets/angle-double-left.svg';
import angleDoubleRightIcon from '../../assets/angle-double-right.svg';
import angleLeftIcon from '../../assets/angle-left.svg';
import angleRightIcon from '../../assets/angle-right.svg';
import ellipsisIcon from '../../assets/ellipsis-h.svg';

import paginationStyles from './pagination.module.css';

export interface PaginationProps {
    setPageNumber: Function;
    decrementPageNumber: Function;
    totalRecords: number;
    recordsPerPage: number;
    pageNumber: number;
    incrementPageNumber: Function;
    setRecordsPerPage: Function;
}

const Pagination: FC<PaginationProps> = ({
    setPageNumber,
    decrementPageNumber,
    totalRecords,
    recordsPerPage,
    pageNumber,
    incrementPageNumber,
    setRecordsPerPage,
}) => {
    const [pageList, setPageList] = useState<Array<number | JSX.Element>>([]);
    const PaginationOptions = [5, 10, 15, 25, 50];
    useEffect(() => {
        setPagination();
    }, [pageNumber, recordsPerPage]);

    useEffect(() => {
        if (
            totalRecords != null ||
            totalRecords !== undefined ||
            totalRecords > 0
        ) {
            setPagination();
        } else {
            setPageList([]);
        }
    }, [totalRecords]);

    const setPagination = (): void => {
        const paginationCount = Math.ceil(totalRecords / recordsPerPage);
        if (totalRecords === 0) {
            setPageList([]);
        } else if (paginationCount <= 5) {
            const arr = [];
            for (let i = 1; i <= paginationCount; i++) {
                arr.push(i);
            }
            setPageList(arr);
        } else if (
            paginationCount > 5 &&
            pageNumber > 3 &&
            pageNumber < paginationCount - 2
        ) {
            setPageList([
                1,
                <img
                    key={'pagination_ellipsis_left'}
                    height={18}
                    src={ellipsisIcon}
                />,
                pageNumber - 1,
                pageNumber,
                pageNumber + 1,
                <img
                    key={'pagination_ellipsis_right'}
                    height={18}
                    src={ellipsisIcon}
                />,
                paginationCount,
            ]);
        } else if (paginationCount > 5 && pageNumber <= 3) {
            setPageList([
                1,
                2,
                3,
                4,
                <img
                    key={'pagination_ellipsis_right'}
                    height={24}
                    src={ellipsisIcon}
                />,
                paginationCount,
            ]);
        } else if (
            paginationCount > 5 &&
            pageNumber > 3 &&
            pageNumber === paginationCount
        ) {
            setPageList([
                1,
                <img
                    key={'pagination_ellipsis_left'}
                    height={24}
                    src={ellipsisIcon}
                />,
                paginationCount - 3,
                paginationCount - 2,
                paginationCount - 1,
                paginationCount,
            ]);
        } else if (
            paginationCount > 5 &&
            pageNumber > 3 &&
            (pageNumber === paginationCount - 1 ||
                pageNumber === paginationCount - 2)
        ) {
            setPageList([
                1,
                <img
                    key={'pagination_ellipsis_left'}
                    height={24}
                    src={ellipsisIcon}
                />,
                paginationCount - 3,
                paginationCount - 2,
                paginationCount - 1,
                paginationCount,
            ]);
        }
    };

    return (
        <div className={paginationStyles['pagination-wrapper']}>
            <div
                data-qa={'pagination_angleDoubleLeft'}
                onClick={() => setPageNumber(1)}
                className={
                    paginationStyles[
                        pageNumber === 1
                            ? 'pagination-element-inactive'
                            : 'pagination-element'
                    ]
                }
            >
                <img height={22} src={angleDoubleLeftIcon} />
            </div>
            <div
                data-qa={'pagination_angleSingleLeft'}
                onClick={() => decrementPageNumber()}
                className={
                    paginationStyles[
                        pageNumber === 1
                            ? 'pagination-element-inactive'
                            : 'pagination-element'
                    ]
                }
            >
                <img height={22} src={angleLeftIcon} />
            </div>
            {pageList.map((pgNum: number | JSX.Element, index: number) => (
                <div
                    key={`pagination_${index}`}
                    onClick={() =>
                        typeof pgNum === 'number' && setPageNumber(pgNum)
                    }
                    className={
                        typeof pgNum === 'number'
                            ? pgNum === pageNumber
                                ? paginationStyles['pagination-element-active']
                                : paginationStyles['pagination-element']
                            : paginationStyles['pagination-ellipsis']
                    }
                >
                    {pgNum}
                </div>
            ))}

            <div
                data-qa={'pagination_angleSingleRight'}
                onClick={() => incrementPageNumber()}
                className={
                    paginationStyles[
                        pageNumber === Math.ceil(totalRecords / recordsPerPage)
                            ? 'pagination-element-inactive'
                            : 'pagination-element'
                    ]
                }
            >
                <img height={22} src={angleRightIcon} />
            </div>
            <div
                data-qa={'pagination_angleDoubleRight'}
                onClick={() =>
                    setPageNumber(Math.ceil(totalRecords / recordsPerPage))
                }
                className={
                    paginationStyles[
                        pageNumber === Math.ceil(totalRecords / recordsPerPage)
                            ? 'pagination-element-inactive'
                            : 'pagination-element'
                    ]
                }
            >
                <img height={22} src={angleDoubleRightIcon} />
            </div>
            <div className={paginationStyles['pagination-dropdown-container']}>
                <select
                    data-qa={'pagination_dropdown'}
                    className={paginationStyles['pagination-dropdown']}
                    onChange={(e) => setRecordsPerPage(Number(e.target.value))}
                    defaultValue={recordsPerPage}
                >
                    {PaginationOptions.map((value) => (
                        <option
                            key={`pagination_dropdown_option_${value}`}
                            data-qa={`pagination_dropdown_option_${value}`}
                            value={value}
                        >
                            {value}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default Pagination;
