import React, { useEffect, useState } from 'react';
import {
    CartesianGrid,
    Label,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import EmptyScreen from '../../../components/EmptyScreen';
import { CHART_VALUES } from '../../../constants';

import styles from './style.module.css';

const SystemLogs = ({
    graphData,
    seekVideoToPoint,
}: {
    graphData: any;
    activieTime: any;
    seekVideoToPoint: Function;
}) => {
    const [zeroState, setZeroState] = useState(false);

    useEffect(() => {
        setZeroState(graphData?.usageData?.length ? false : true);
    }, [graphData]);

    const onChartClick = (e: any) => {
        if (e) {
            const { activeLabel } = e;
            seekVideoToPoint(Number(activeLabel));
        }
    };

    const convertBytesToGB = (value: number) => {
        return `${(value / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    };

    return (
        <>
            {zeroState && <EmptyScreen type={'system info'} />}
            {!zeroState && (
                <div className={styles.mainDiv}>
                    <div className={styles.mainContent}>
                        <ResponsiveContainer
                            height="100%"
                            width="100%"
                            minHeight={400}
                            minWidth={720}
                        >
                            <LineChart
                                onClick={onChartClick}
                                data={graphData?.usageData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="baseTime"
                                    type="number"
                                    domain={[0, 'dataMax']}
                                >
                                    <Label
                                        value={CHART_VALUES.X_AXIS_LABEL}
                                        offset={0}
                                        position="insideBottom"
                                    />
                                </XAxis>
                                <YAxis
                                    domain={[0, 100]}
                                    allowDataOverflow
                                    dataKey="usage"
                                    label={{
                                        value: 'CPU Percentage',
                                        angle: -90,
                                        position: 'insideLeft',
                                    }}
                                />
                                <Tooltip />
                                <Line
                                    name={CHART_VALUES.LINE_NAME}
                                    type="monotone"
                                    dataKey="usage"
                                    stroke="#82ca9d"
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div className={styles.systemDetails}>
                        <div className={styles.detailGrid}>
                            <b>Internet Speed</b>
                            <div>{graphData?.internetSpeed} Mbps</div>
                            <b>IP Address</b>
                            <div>{graphData?.ipAddress}</div>
                            <b>Arch Name</b>
                            <div>{graphData?.archName}</div>
                            <b>Model Name</b>
                            <div>{graphData?.modelName}</div>
                            <b>Available Memory</b>
                            <div>
                                {convertBytesToGB(
                                    graphData?.memory?.availableCapacity ?? 0
                                )}
                            </div>
                            <b>Total Memory</b>{' '}
                            <div>
                                {convertBytesToGB(
                                    graphData?.memory?.capacity ?? 0
                                )}
                            </div>
                            {graphData?.platform && (
                                <>
                                    <b>Platform</b>
                                    <div>{`${graphData?.platform}`}</div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SystemLogs;
