const timeAdder = (
    data: object[],
    timeIndex: string,
    baseTime: string,
    needTimeConvertion: boolean
): object[] => {
    const baseTimeConverted = Number(baseTime);
    const res = data.map((item: any) => {
        return {
            ...item,
            baseTime:
                (needTimeConvertion
                    ? Number(item[timeIndex]) / 1000
                    : Number(item[timeIndex])) -
                baseTimeConverted / 1000,
        };
    });
    return res;
};
function fmtMSS(s: number) {
    let num: any = Number(s.toFixed(0));
    return (num - (num %= 60)) / 60 + (9 < num ? ':' : ':0') + num;
}
export { fmtMSS,timeAdder };
