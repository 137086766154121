import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

import logo from '../../assets/logo.svg';
import Footer from '../../components/Footer';
import CheckSignIn from '../../components/GoogleSignInButton';
import { APPLICATION_NAME } from '../../constants';
import { loginPageLocale } from '../../utils/locale/loginPageLocale';

import styles from './styles.module.css';

const LoginPage = () => {
    const location: any = useLocation();
    useEffect(() => {
        if (location?.state?.error === 'notAllowed') {
            toast.error('Not allowed');
        }
    }, [location.state]);

    return (
        <>
            <div className={styles.content}>
                <div className={styles.landing_block}>
                    <img
                        className={styles.image}
                        src={logo}
                        alt=""
                    />
                    <p className={styles.brand_name}>{APPLICATION_NAME}</p>
                </div>
                <div className={styles.login_block}>
                    <div className={styles.login_wrapper}>
                        <CheckSignIn/>
                    </div>
                    <p className={styles.login_text}>
                        {loginPageLocale.sign_in_hint}
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default LoginPage;
