import React, { useEffect, useState } from 'react';

import angleDown from '../../assets/angle-down.svg';
import angleRight from '../../assets/angle-right.svg';
import closeIcon from '../../assets/close.svg';
import { isJsonString } from '../../utils/common';
const GridDrawer = ({
    data,
    setShowNetworkExpand,
}: {
    data: any;
    setShowNetworkExpand: Function;
}) => {
    const defaultDetailsState = {
        reqHeader: false,
        reqBody: false,
        resHeader: false,
        resBody: false,
    };

    const [showDetails, setShowDetails] = useState<any>(defaultDetailsState);

    useEffect(() => {
        setShowDetails(defaultDetailsState);
    }, [data]);

    const toggleDetails = (key: string) => {
        setShowDetails((previousValue: any) => {
            return { ...previousValue, [key]: !showDetails[key] };
        });
    };

    return (
        <>
            <div className="grid__drawer drawer">
                <div className="drawer__header">
                    <a
                        onClick={() => setShowNetworkExpand(false)}
                        className="drawer__close"
                    >
                        <img
                            className="drawer__icon"
                            src={closeIcon}
                            alt="Close Drawer"
                        />
                    </a>
                    <h2 className="drawer__title">Details</h2>
                </div>
                <div className="drawer__content accordion">
                    <div className="accordion__item">
                        <div
                            className="accordion__header"
                            onClick={() => toggleDetails('reqHeader')}
                        >
                            <img
                                className="accordion__icon"
                                src={
                                    showDetails.reqHeader
                                        ? angleDown
                                        : angleRight
                                }
                                alt="Toggle Accordion"
                            />
                            <h2 className="accordion__title">
                                Request Headers
                            </h2>
                        </div>
                        <div
                            className={`accordion__content list ${
                                showDetails.reqHeader ? '' : 'showDetail'
                            }`}
                        >
                            {data?.requestHeaders?.map(
                                (header: any, index: number) => (
                                    <div key={index} className="list__item">
                                        <div className="list__label">
                                            {header.name}:
                                        </div>
                                        <div className="list__value">
                                            {header.value}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                    {data.requestBody && (
                        <div className="accordion__item">
                            <div
                                className="accordion__header"
                                onClick={() => toggleDetails('reqBody')}
                            >
                                <img
                                    className="accordion__icon"
                                    src={
                                        showDetails.reqBody
                                            ? angleDown
                                            : angleRight
                                    }
                                    alt="Toggle Accordion"
                                />
                                <h2 className="accordion__title">
                                    Request Body
                                </h2>
                            </div>
                            <div
                                className={`accordion__content list ${
                                    showDetails.reqBody ? '' : 'showDetail'
                                }`}
                            >
                                <div className="list__item">
                                    <div className="list__label">
                                        {data?.requestBody ? (
                                            <pre>
                                                {JSON.stringify(
                                                    data?.requestBody,
                                                    null,
                                                    2
                                                )}
                                            </pre>
                                        ) : (
                                            String(data?.requestBody)
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {data.responseHeaders && (
                        <div className="accordion__item">
                            <div
                                className="accordion__header"
                                onClick={() => toggleDetails('resHeader')}
                            >
                                <img
                                    className="accordion__icon"
                                    src={
                                        showDetails.resHeader
                                            ? angleDown
                                            : angleRight
                                    }
                                    alt="Toggle Accordion"
                                />
                                <h2 className="accordion__title">
                                    Response Headers
                                </h2>
                            </div>
                            <div
                                className={`accordion__content list ${
                                    showDetails.resHeader ? '' : 'showDetail'
                                }`}
                            >
                                {data?.responseHeaders?.map(
                                    (header: any, index: number) => (
                                        <div key={index} className="list__item">
                                            <div className="list__label">
                                                {header.name}:
                                            </div>
                                            <div className="list__value">
                                                {header.value}
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    )}
                    {data.responseBody && (
                        <div className="accordion__item">
                            <div
                                className="accordion__header"
                                onClick={() => toggleDetails('resBody')}
                            >
                                <img
                                    className="accordion__icon"
                                    src={
                                        showDetails.resBody
                                            ? angleDown
                                            : angleRight
                                    }
                                    alt="Toggle Accordion"
                                />
                                <h2 className="accordion__title">
                                    Response Body
                                </h2>
                            </div>
                            <div
                                className={`accordion__content list ${
                                    showDetails.resBody ? '' : 'showDetail'
                                }`}
                            >
                                <div className="list__item">
                                    <div className="list__label">
                                        Base64:{' '}
                                        {String(
                                            data?.responseBody?.base64Encoded
                                        )}
                                    </div>
                                </div>
                                <div className="list__item">
                                    <div className="list__label"></div>
                                    <br />
                                    <div className="list__value">
                                        {data?.responseBody?.body &&
                                        isJsonString(
                                            data?.responseBody?.body
                                        ) ? (
                                                <pre>
                                                    {JSON.stringify(
                                                        JSON.parse(
                                                            data?.responseBody?.body
                                                        ),
                                                        null,
                                                        2
                                                    )}
                                                </pre>
                                            ) : (
                                                String(data?.responseBody?.body)
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
export default GridDrawer;
