import Cookies from 'js-cookie';

type cookieKeyTypes = 'google-auth' | 'profile';

export const setCookie = (key: cookieKeyTypes, value: string | JSON): void => {
    Cookies.set(key, JSON.stringify(value));
};

const getCookie = (key: cookieKeyTypes): string => {
    return Cookies.get(key) || '';
};
const clearCookie = async (key: cookieKeyTypes) => {
    await Cookies.remove(key, { path: '/', domain: 'localhost' });
};
const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
};
export { clearCookie, deleteAllCookies,getCookie };
