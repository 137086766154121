import React from 'react';

const Footer = () => {
    return (
        <div className='footer'>
            © 2017 CareStack ®, Good Methods Global Inc. All rights reserved.
        </div>
    );
};

export default Footer;
