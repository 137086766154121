import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';

import Loader from '../../components/Loader';
import {
    useLazyGetUserDetailsQuery,
    useLazyLogoutUserSessionQuery,
} from '../../features/user/userApiSlice';
import {
    add,
    clear,
    updateAuthentication,
} from '../../features/user/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setItem } from '../../utils/common/local-storage.index';
import { deleteAllCookies, getCookie } from '../../utils/cookies';
import { loginPageLocale } from '../../utils/locale/loginPageLocale';

const PrivatePageRedirect: React.FC = () => {
    const [getUserInfo] = useLazyGetUserDetailsQuery();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [clearSession, { isSuccess: logoutSuccess }] =
        useLazyLogoutUserSessionQuery();
    const user = useAppSelector((state) => state.user);

    const isAuth = getCookie('google-auth');
    let toastDueToRerender = 0;

    useEffect(() => {
        if (isAuth) {
            getUserInfo()
                .unwrap()
                .then((userInfo) => {
                    dispatch(
                        add({
                            profileObject: JSON.parse(getCookie('profile')),
                            role: userInfo.data.data.role,
                            isAuthenticated: true,
                            status: 'SUCCESS',
                        })
                    );
                })
                .catch((e) => {
                    let toastMessage = '';
                    if (e.status === 404 || e.status === 503) {
                        toastMessage = loginPageLocale.toast.something_went_wrong;
                    } else if (e.status === 401 && e?.data?.message === loginPageLocale.token_expired) {
                        toastMessage = loginPageLocale.token_expired_please_login_again;
                    } else {
                        toastMessage = loginPageLocale.toast.unauthorized_message;
                    }

                    clearSession();
                    if (toastDueToRerender === 0) {
                        toast.error(
                            toastMessage,
                            { position: 'bottom-center',}
                        );
                        toastDueToRerender = 1;
                    }

                    dispatch(clear());
                    dispatch(updateAuthentication(false));
                    googleLogout();
                });
        }
    }, [location.pathname]);

    useEffect(() => {
        if (!isAuth) {
            setItem('path', location.pathname);
            navigate('/buglogin');
        }
    }, [isAuth]);

    useEffect(() => {
        if (logoutSuccess) {
            deleteAllCookies();
            navigate('/buglogin');
        }
    }, [logoutSuccess, navigate]);

    return <>{user.status === 'SUCCESS' ? <Outlet /> : <Loader />}</>;
};

export default PrivatePageRedirect;
