import React from 'react';

import styles from './style.module.css';
const LostPage = () => {
    return (
        <>
            <div className={styles.main}>
                <div className={styles.fof}>
                    <h1 className={styles.fofh1}>Error 404</h1>
                </div>
            </div>
        </>
    );
};
export default LostPage;
