import React, {
    BrowserRouter as Router,
    Route,
    Routes,
} from 'react-router-dom';

import AdminPage from '../containers/adminPage/adminRoute';
import PrivatePageRedirect from '../containers/PrivatePage/PrivatePageRedirect';
import IssueDetails from '../pages/IssueDetails';
import Landing from '../pages/Landing';
import LoginPage from '../pages/LoginPage';
import LostPage from '../pages/LostPage';
import Reports from '../pages/Reports';
import Users from '../pages/Users';

const AppRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<PrivatePageRedirect />}>
                    <Route path="/" element={<Landing />} />
                    <Route path="/auth-success" element={<IssueDetails />} />
                    <Route
                        path="/reports"
                        element={
                            <AdminPage>
                                <Reports />
                            </AdminPage>
                        }
                    />
                    <Route
                        path="/users"
                        element={
                            <AdminPage>
                                <Users />
                            </AdminPage>
                        }
                    />

                    <Route path="/issue/:issueId" element={<IssueDetails />} />
                </Route>

                <Route path="/buglogin" element={<LoginPage />} />
                <Route path="*" element={<LostPage />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
