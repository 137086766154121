import React, { MouseEventHandler } from 'react';

// Styles
import buttonStyles from './button.module.scss';

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  info = 'info',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  neutral = 'neutral',
  disabled ='disabled'
}

export interface ButtonProps {
  children: React.ReactNode;
  type: ButtonType;
  dataQa: string;
  styles?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
    children,
    type,
    styles = '',
    onClick = () => {
        throw new Error('Not Implemented');
    },
    dataQa,
    disabled = false,
    ...args
}) => {
    return (
        <button
            {...args}
            data-qa={dataQa}
            onClick={onClick}
            disabled={disabled}
            className={[styles, buttonStyles[`c-button--${type}`]].join(' ')}
        >
            {children}
        </button>
    );
};

export default Button;
