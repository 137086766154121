import { Navigate, useLocation } from 'react-router-dom';

import Loader from '../../components/Loader';
import { useAppSelector } from '../../store/hooks';

const AdminPage = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();

    const { loading, role } = useAppSelector((state) => state.user);

    if (loading) {
        return <Loader></Loader>;
    }

    if (role.roleId !== 1) {
        return <Navigate to="/404" state={{ from: location }} />;
    }
    return children;
};

export default AdminPage;
