import React from 'react';

// Styles
import buttonGroupStyles from './button-group.module.scss';

export enum ButtonGroupAlignment {
  left = 'left',
  center = 'center',
  right = 'right',
}

export interface ButtonGroupProps {
  children: React.ReactNode;
  alignment: ButtonGroupAlignment;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ children, alignment }) => {
    return (
        <div
            className={`${buttonGroupStyles['c-button-group']} ${buttonGroupStyles[alignment]}`}
        >
            {children}
        </div>
    );
};

export default ButtonGroup;
