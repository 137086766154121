const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@carestack.com$/
        );
};

const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
};

const generateQueryString = (
    params: { [key: string]: string },
    generalQueryOrder: string[] = [
        'emailKey',
        'roleIdKey',
        'sortBy',
        'sortOrder',
        'limit',
        'offset',
    ]
) => {
    return Object.keys(params)
        .sort(
            (a, b) =>
                generalQueryOrder.indexOf(a) - generalQueryOrder.indexOf(b)
        )
        .filter((param) => params[param])
        .map((param) => `${param}=${params[param]}`)
        .join('&');
};

export { generateQueryString,toTitleCase, validateEmail };
