import React, { useEffect, useState } from 'react';

import TextField from '../../../containers/text-filed';
import { useFetchBlobURLQuery } from '../../../features/issue/issueApiSlice';
import { formatDateAndTime } from '../../../utils/common';
import { previewLocale } from '../../../utils/locale/searchBarLocale';

const MainContent = ({
    data,
    setActiveTime,
    switchMetaTabs,
    metaTab,
}: {
    data: any;
    setActiveTime: Function;
    switchMetaTabs: Function;
    metaTab: string;
}) => {
    const { data: videoURL } = useFetchBlobURLQuery(
        {
            fileName: data?.file?.metaData?.originalname,
            topLevelDomain: data?.topLevelDomain,
        },
        { refetchOnMountOrArgChange: true }
    );

    const [issueVideo, setIsuueVideo] = useState<string>();
    const [downloadStatus, setDownloadStatus] = useState<string>(
        previewLocale.STARTING_DOWNLOAD
    );

    function progress(loaded: number, total: number) {
        if (total > 0)
            setDownloadStatus(
                previewLocale.DOWNLOADING_VIDEO +
                    Math.round((loaded / total) * 100) +
                    previewLocale.PERCENTAGE
            );
    }

    useEffect(() => {
        if (videoURL) {
            try {
                (async () => {
                    const response = await fetch(videoURL);
                    const contentLength =
                        response.headers.get('content-length') || '';
                    const total = parseInt(contentLength, 10);
                    let loaded = 0;
                    const res = new Response(
                        new ReadableStream({
                            async start(controller) {
                                const reader = response.body?.getReader();
                                for (;;) {
                                    if (reader) {
                                        const { done, value } =
                                            await reader.read();
                                        if (done) break;
                                        loaded += value.byteLength;
                                        progress(loaded, total);
                                        controller.enqueue(value);
                                    }
                                }
                                controller.close();
                            },
                        })
                    );
                    const blob = await res.blob();
                    setIsuueVideo(URL.createObjectURL(blob));
                    setDownloadStatus('');
                })();
            } catch (e) {
                setDownloadStatus('Video Download failed');
            }

            // below commented code is required for time being, this will be removed once everthing works fine in testing
            // fetch(videoURL)
            //     .then((res) => {
            //         res.blob()
            //             .then((video) => {
            //                 setIsuueVideo(URL.createObjectURL(video));
            //             })
            //             .catch(console.error);
            //     })
            //     .catch(console.error);
        }
    }, [videoURL]);

    const onPlaying = () => {
        const currentTime = (document.getElementById('feed') as any)
            ?.currentTime;
        setActiveTime((prev: any) => {
            return {
                before: prev.now,
                now: currentTime,
                timestambBefore: prev.timestampNow,
                timestampNow: Date.now(),
            };
        });
    };

    return (
        <React.Fragment>
            <div className="container__left">
                <div className="video">
                    {data && data?.id && (
                        <video
                            onTimeUpdate={onPlaying}
                            id="feed"
                            className="video__player"
                            src={issueVideo}
                            controls
                        ></video>
                    )}
                    <div>{downloadStatus}</div>
                    <div className="video__meta">
                        Recorded on: {formatDateAndTime(data?.issueCreatedAt)}
                    </div>
                    <div className="meta__block">
                        <div className="panel__header">
                            <div className="tab">
                                <a
                                    onClick={() => switchMetaTabs('bug')}
                                    className={`tab__button ${
                                        metaTab == 'bug' && 'selected'
                                    }`}
                                >
                                    Bug details
                                </a>
                                <a
                                    onClick={() => switchMetaTabs('reporter')}
                                    className={`tab__button ${
                                        metaTab == 'reporter' && 'selected'
                                    }`}
                                >
                                    Reporter details
                                </a>
                            </div>
                        </div>
                        {metaTab === 'bug' && (
                            <div className="meta__wrapper">
                                <div className="video__title__block">
                                    <TextField
                                        title="Title"
                                        value={data?.title}
                                    ></TextField>
                                </div>
                                <div className="video__title__block">
                                    <TextField
                                        title="No of systems affected"
                                        value={
                                            data?.file?.practiceInfo
                                                ?.systemCount ?? ''
                                        }
                                    ></TextField>
                                </div>
                                <div className="video__description__block">
                                    <TextField
                                        title="Description"
                                        value={
                                            data?.description ||
                                            'No Description Provided'
                                        }
                                    ></TextField>
                                </div>
                            </div>
                        )}
                        {metaTab === 'reporter' && (
                            <div className="meta__wrapper">
                                <div className="video__title__block">
                                    <TextField
                                        title="Practice name"
                                        value={
                                            data?.file?.practiceInfo
                                                ?.practiceName ?? ''
                                        }
                                    ></TextField>
                                </div>
                                <div className="video__title__block">
                                    <TextField
                                        title="Name"
                                        value={
                                            data?.file?.practiceInfo
                                                ?.userName ?? ''
                                        }
                                    ></TextField>
                                </div>
                                <div className="video__title__block">
                                    <TextField
                                        title="Phone"
                                        value={
                                            data?.file?.practiceInfo?.phone ??
                                            ''
                                        }
                                    ></TextField>
                                </div>
                                <div className="video__title__block">
                                    <TextField
                                        title="Email"
                                        value={data?.email ?? ''}
                                    ></TextField>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default MainContent;
