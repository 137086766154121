import { APPLICATION_NAME } from '../../constants';

export const loginPageLocale = {
    sign_in_hint: 'Sign in with your Google account to get started',
    button: {
        gmail_btn_loading: 'Loading...',
    },
    toast: {
        unauthorized_message: `User not authorised to access CareStack ${APPLICATION_NAME}`,
        something_went_wrong: 'Something went wrong',
    },
    token_expired: 'Token expired',
    token_expired_please_login_again: 'Session timed out',
};
