export const enum RoleEnum {
    ADMIN,
    L1,
    L2,
    L3,
}
export interface Role {
    roleId: number;
    roleName: string;
    roleEnum: RoleEnum;
}
