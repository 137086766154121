import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

import { ReactComponent as CheckIcon } from '../../../assets/check.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/trash.svg';
import { ReactComponent as CrossIcon } from '../../../assets/x.svg';
import ModalConfirmation from '../../../containers/modal-confirmation';
import {
    useDeleteUserMutation,
    useUpdateUserMutation,
} from '../../../features/user/userApiSlice';
import { Role } from '../../../utils/interfaces/role.model';
import { toTitleCase } from '../../../utils/string';
import RolesSelect from '../RolesFilter';

import styles from './style.module.css';
const UserCard: FC<{
    employeeId: string;
    email: string;
    role: Role;
    onUpdate: (updatedUser: any) => void;
    onDelete: (deletedUser: any) => void;
}> = ({ email, employeeId, role, onUpdate, onDelete }) => {
    const [editing, setEditing] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [userEditForm, setUserEditForm] = useState({
        role: { value: role, errors: {}, errorState: false },
    });
    const [updateUser, { data: updatedUser, isSuccess, isError, isLoading }] =
        useUpdateUserMutation();
    const [
        deleteUser,
        {
            data: deletedUser,
            isSuccess: userDeletedSuccess,
            isError: userDeletedFail,
            isLoading: Deleting,
        },
    ] = useDeleteUserMutation();

    const handleRoleChange = ({
        label,
        value,
    }: {
        label: string;
        value: string;
    }) => {
        setUserEditForm((prev) => ({
            role: {
                ...prev.role,
                value: { ...prev.role.value, roleId: +value, roleName: label },
            },
        }));
    };
    const printErrors = (errors: any) => {
        return (
            <>
                {Object.keys(errors).map((error, index) => {
                    return (
                        <span key={`error-${index + 1}`}>{errors[error]}</span>
                    );
                })}
            </>
        );
    };

    const _deleteUser = () => {
        deleteUser({ id: employeeId });

        onDelete({ employeeId, email, role });
    };
    const saveData = () => {
        updateUser({
            id: employeeId,
            data: { email, roleId: userEditForm.role.value.roleId },
        });
    };

    useEffect(() => {
        if (userDeletedSuccess && deletedUser) {
            toast.success('User Deleted Successfully');
            onDelete(deletedUser);
            setDeleteModal(false);
        }
    }, [userDeletedSuccess]);

    useEffect(() => {
        if (userDeletedFail) {
            setDeleteModal(false);
            toast.error('User Not Deleted');
        }
    }, [userDeletedFail]);

    useEffect(() => {
        if (isSuccess) {
            if (updatedUser) toast.success('User Updated Successfully');
            onUpdate(updatedUser);
            setEditing(false);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            setEditing(false);
            toast.error('User Update Failed');
        }
    }, [isError]);

    useEffect(() => {
        setUserEditForm({
            role: { value: role, errors: {}, errorState: false },
        });
    }, [role]);

    return (
        <>
            <div className={styles.row}>
                <div className={styles.col}>{email}</div>
                <div className={[styles.col, styles.colWithAction].join(' ')}>
                    {!editing && toTitleCase(role.roleName)}
                    {editing && (
                        <>
                            <div
                                style={{ position: 'relative', width: '100%' }}
                            >
                                <RolesSelect
                                    name="roleId"
                                    value={userEditForm.role.value.roleId}
                                    onChange={(e) => {
                                        if (e) handleRoleChange(e);
                                    }}
                                    customStyles={styles.selectInput}
                                    errorState={userEditForm.role.errorState}
                                    defaultOptionText="Select User Role"
                                />
                                <div className={[styles.errorStyle].join(' ')}>
                                    {printErrors(userEditForm.role.errors)}
                                </div>
                            </div>
                            {!isLoading && (
                                <button
                                    className={styles.svgButton}
                                    onClick={saveData}
                                >
                                    <CheckIcon
                                        stroke="#199958"
                                        className={[
                                            styles.actions,
                                            userEditForm.role.errorState &&
                                                styles.disabled,
                                        ].join(' ')}
                                    />
                                </button>
                            )}
                            {isLoading && <div className={styles.spin}></div>}
                            <button
                                className={styles.svgButton}
                                onClick={() => setEditing(false)}
                            >
                                <CrossIcon
                                    stroke="#E4584C"
                                    className={[styles.actions].join(' ')}
                                />
                            </button>
                        </>
                    )}
                    {!editing && (
                        <div className={styles.actions}>
                            <EditIcon
                                className={styles.editAction}
                                onClick={() => setEditing(true)}
                            />
                            <DeleteIcon
                                className={styles.deleteAction}
                                onClick={() => {
                                    setDeleteModal(true);
                                }}
                            />
                        </div>
                    )}
                </div>
                {deleteModal && (
                    <ModalConfirmation
                        title="Confirm"
                        text="Are you sure you want to delete this user ?"
                        confirmLabel="Yes"
                        cancelLabel="No"
                        confirmLoading={Deleting}
                        onConfirm={_deleteUser}
                        onCancel={() => setDeleteModal(false)}
                        onClose={() => setDeleteModal(false)}
                        isErrorTitle={true}
                    />
                )}
            </div>
        </>
    );
};
export default UserCard;
