import { ChangeEvent, FC, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { useLazyFetchIssueAccessQuery } from '../../features/issue/issueApiSlice';
import { searchBarLocale } from '../../utils/locale/searchBarLocale';

import styles from './style.module.css';

interface CustomStyles {
    customStyles: string;
    isLoading: Function;
    isHome: boolean;
}

const SearchBar: FC<CustomStyles> = ({ customStyles, isLoading, isHome }) => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [getIssueDetails, { data: issueDetails, error, isFetching }] =
        useLazyFetchIssueAccessQuery();
        
    const changeSearchValueHandler = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setErrorMessage('');
        setSearchValue(event.target.value);
    };

    const searchHandler = (event: any) => {
        event.preventDefault();
        if (searchValue === '') {
            setErrorMessage(searchBarLocale.errorMessages.NOT_FOUND);
        } else {
            getIssueDetails(searchValue);
        }
    };

    useEffect(() => {
        isFetching ? isLoading(true) : isLoading(false);
    }, [isFetching]);

    useEffect(() => {
        isLoading(false);
        if (error) {
            if ((error as any).status === 404) {
                setErrorMessage(searchBarLocale.errorMessages.NOT_FOUND);
                !isHome && toast.error(searchBarLocale.errorMessages.NOT_FOUND);
            }
            if ((error as any).status === 403) {
                toast.error(searchBarLocale.errorMessages.FORBIDDEN);
            }
            if ((error as any).status === 401) {
                toast.error(searchBarLocale.errorMessages.EXPIRED_TOKEN);
            }
        }
    }, [error]);

    useEffect(() => {
        if (issueDetails) {
            isLoading(false);
            setSearchValue('');
            navigate(`/issue/${searchValue}`);
        }
    }, [issueDetails]);

    return (
        <>
            <div
                className={[
                    styles.search_input_wrapper,
                    customStyles,
                    errorMessage && styles.search_error_container,
                ].join(' ')}
            >
                <form onSubmit={searchHandler}>
                    <input
                        className={[
                            styles.search_input,
                            errorMessage && styles.search_error_input,
                        ].join(' ')}
                        type="text"
                        placeholder={searchBarLocale.searchPlaceholder}
                        onChange={changeSearchValueHandler}
                        value={searchValue}
                    />
                </form>
                <SearchIcon
                    className={styles.search_icon}
                    title='Search'
                    onClick={searchHandler}
                />
            </div>
            {isHome && errorMessage && (
                <p className={styles.error_message}>{errorMessage}</p>
            )}
        </>
    );
};

export default SearchBar;
