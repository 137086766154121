interface ShareModalLocaleInterface {
    toast: {
        user_added_and_issue_shared: string;
        try_again_message: string;
        valid_email_message: string;
        role_required: string;
        require_message: string;
        share_success_message: string;
        email_mandatory_message: string;
    };
    radioGroupHeading: string;
    l2SupportRadioLabel: string;
    l2SupprtRadioName: string;
    l3SupportRadioLabel: string;
    l3SupprtRadioName: string;
    dropdownHeading: string;
    asterisk: string;
    shareButton: string;
    cancelButton: string;
    dropdownPlaceholder: string;
}

export const shareModalLocale: ShareModalLocaleInterface = {
    toast: {
        user_added_and_issue_shared: 'User added and issue shared!',
        try_again_message: 'Failed! Try again.',
        valid_email_message: 'Please enter valid email',
        role_required: 'Please enter user role',
        require_message: 'All fields are mandatory',
        share_success_message: 'Issue shared!',
        email_mandatory_message: 'User EmailId is mandatory',
    },
    radioGroupHeading: 'Share reported issue to:',
    l2SupportRadioLabel: 'L2 Support Team',
    l2SupprtRadioName: 'L2',
    l3SupportRadioLabel: 'L3 Support Team',
    l3SupprtRadioName: 'L3',
    dropdownHeading: 'Share to:',
    asterisk: ' *',
    shareButton: 'Share',
    cancelButton: 'Cancel',
    dropdownPlaceholder: 'User Email'
};
