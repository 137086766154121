import React, { FC, useEffect } from 'react';

const useOutsideClickAlert: FC<{
    ref: React.RefObject<HTMLInputElement>;
    onOutsideClick: Function;
}> = ({ ref, onOutsideClick }): any => {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};

export { useOutsideClickAlert };
