import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import brandIcon from '../../assets/brand.svg';
import { APPLICATION_NAME } from '../../constants';
import Loader from '../Loader';
import SearchBar from '../SearchBar';

import RightMenu from './RightMenu';

import styles from './style.module.css';
const Header: React.FC<{ isHome: boolean }> = ({ isHome }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const goToHome = () => {
        navigate('/');
    };

    return (
        <>
            {isLoading && <Loader />}
            <div className={[styles.header, styles.appHeader].join(' ')}>
                {!isHome && (
                    <div className={styles.headerLeftMenu}>
                        <div
                            className={styles.headerWrapper}
                            onClick={goToHome}
                        >
                            <img
                                className="header__logo"
                                src={brandIcon}
                                alt="CareStack brand"
                            />
                            <h1 className="header__title">
                                {APPLICATION_NAME}
                            </h1>
                        </div>
                        <SearchBar
                            isLoading={setIsLoading}
                            customStyles={styles.searchbar_div}
                            isHome={false}
                        />
                    </div>
                )}
                <div className={styles.headerRighMenu}>
                    <RightMenu />
                </div>
            </div>
        </>
    );
};

export default Header;
